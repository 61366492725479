<template>
    <div>
        <v-card class="text-left pa-2 ma-0" flat tile>
            <v-card-subtitle class="text-left font-weight-bold text-h6">{{news.title}}</v-card-subtitle>
            <v-card-subtitle class="pt-2">
                <v-sheet v-html="news.content" class="news_detail">
                </v-sheet>
            </v-card-subtitle>
        </v-card>
        <template v-if="video">
            <videoplayer :video="video" :refresh="refreshChild"></videoplayer>
        </template>
        <!--<template v-else>
                <v-card class="text-left pa-2 ma-0" flat tile>
                    <v-card-subtitle class="text-left font-weight-bold text-subtitle-1">{{news.title}}</v-card-subtitle>
                    <v-card-subtitle class="pt-2">
                        <v-sheet v-html="news.content" class="news_detail">
                        </v-sheet>
                    </v-card-subtitle>
                </v-card>
            </template>-->
    </div>
</template>
<script>
    import VideoPlay from "../components/VideoPlay.vue";
    import DataService from '../services/DataService.js';
    export default {
        props: ['news'],
        name: "NewsDetail",
        components: {
            'videoplayer': VideoPlay,
        },
        data: () => ({
            refreshChild: false,
            video: null,
        }),
        mounted() {
            //console.log("NewsDetail mounted");
            if (this.news.videoId){
                //console.log("get video:", this.news.videoId);
                this.getVideo(this.news.videoId);
            }
        },
        beforeDestroy(){
            //console.log("NewsDetail beforeDestroy");
            //this.video = null;
        },
        watch: {
            news: function (newVal) { // watch it
                //console.log("News Detail - news is changed");
                if (newVal) {
                    if (newVal.videoId) {
                        this.refreshChild = !this.refreshChild;
                        //console.log("watch get video:", newVal.videoId);
                        this.getVideo(newVal.videoId);
                    } else {
                        this.video = null;
                    }
                }
                else {
                    this.video = null;
                }
            }
        },
        methods: {
            async getVideo(videoId) {
                var result = await DataService.getVideo(videoId).then(function (data) {
                    return data.data.result;
                }).catch(function (err) {
                    console.log(err);
                    return null;
                });
                if (result) {
                    var tsData = JSON.parse(result.transcript_data);
                    result.id = videoId;
                    result.transcript = tsData;
                    this.video = result;
                }
            },
        },
    }
</script>
<style>
    .news_detail{
        word-wrap: break-word;
        white-space: normal ;
        word-break: normal;
        overflow: hidden ;
        text-overflow: ellipsis;
    }
    .news_detail img{
        max-width: 100%;
    }
    .news_detail video{
        max-width: 100%;
    }
    .news_detail iframe{
        max-width: 100%;
    }
</style>
