<template>
    <div class="pa-0 transparent">
        <v-container class="pa-0 transparent">
            <div class="pa-0 transparent">
                <!--<div class="shop-card" max-width="374">-->
                <v-card :loading="false" class="mx-0 mb-4 pa-0 transparent" elevation="0" flat tile>
                    <template slot="progress">
                        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                    </template>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" md="7" class="pa-0">
                            <!--<v-carousel cycle :height="calImageHeight2()" class="transparent">-->
                            <v-carousel :height="calImageHeight2()" class="transparent" :show-arrows="false">
                                <v-carousel-item eager v-for="(image, index) in value.info.images" :key="index"
                                    reverse-transition=false 
                                    transition=false>
                                    <v-img eager :src="value.info.images[index]" lazy-src="/loading_icon_128.png" contain height="100%"></v-img>
                                </v-carousel-item>
                            </v-carousel>
                        </v-col>
                        <v-col cols="12" md="5" class="pa-2">
                            <v-card-text class="text-left basil--text shop-intro">
                                <v-row class="pa-0">
                                    <v-col xs="12" sm="12" md="12" cols="xs-12 md-12" class="pa-1">
                                        <v-chip draggable :color="colorSet.background" :class="colorSet.textColor" class="mr-2 my-1 shop-intro" v-for="(tag, index) in value.info.bizType" :key="index">
                                            {{tag}}
                                          </v-chip>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" cols="xs-12 md-6" class="pa-1 shop-intro"
                                        v-if="value.info.name">
                                        <v-icon medium :color="colorSet.buttonColor" class="pr-2">mdi-home</v-icon>
                                        {{value.info.name}}
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" cols="xs-12 md-6" class="pa-1 shop-intro"
                                        v-if="value.info.bizTime">
                                        <v-icon medium :color="colorSet.buttonColor" class="pr-2">mdi-clock-time-two</v-icon>
                                        {{value.info.bizTime}}
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" cols="xs-12 md-6" class="pa-1 shop-intro"
                                        v-if="value.info.phoneNumber.length > 0">
                                        <v-icon medium :color="colorSet.buttonColor" class="pr-2">mdi-phone</v-icon>
                                        <span v-for="(phone,index) in value.info.phoneNumber" :key="index">
                                            {{phone}}
                                            <v-icon medium :color="colorSet.buttonColor" v-if="index < value.info.phoneNumber.length - 1">mdi-minus</v-icon>
                                        </span>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" cols="xs-12 md-6" class="pa-1 shop-intro"
                                        v-if="value.info.address">
                                        <v-icon medium :color="colorSet.buttonColor"  class="pr-2">mdi-map-marker</v-icon>
                                        {{value.info.address}}
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" cols="xs-12 md-6" class="pa-1"
                                        v-if="value.info.address">
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn v-if="value.info.enableChat" color="light-blue" class="ma-2 white--text"
                                    @click="chatWithShop">
                                    Liên hệ
                                    <v-icon small color="white" class="ml-1">
                                        mdi-message-text
                                    </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-card>
                <div >
                <v-lazy :options="{threshold: .5}" transition="fade-transition" >
                    <v-expansion-panels multiple v-model="panel" elevation="0" tile hover flat class="transparent" :readonly="true">
                        <v-expansion-panel v-for="(info, index) in value.info.details" :key="index" class="transparent">
                            <v-expansion-panel-header :id="'id-' + nomalizeText(info.label)" :color="colorSet.background" class="text-left" light>
                                <span class="text-capitalize expansion_panel_title px-2" :class="colorSet.textColor">
                                    <!--<v-icon class="mr-1" :class="colorSet.textColor">mdi-dots-square</v-icon>-->{{info.label}}
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="text-left transparent px-0 mx-2  basil--text">
                                <div class="panel-div" v-html='info.content'></div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    </v-lazy>
                </div>
            </div>
        </v-container>
    </div>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex';
    import {firebaseAuth} from '@/firestore';
    import DataService from "../services/DataService.js";
    import { nomalizeText, createGotoText, parseGotoText} from "../utils/text_utils.js";
    export default {
        props: ['value','common', 'navigate','gotoX','leaveX'],
        data(){
            return {
                selection : 0,
                content_selected_index: 0,
                sheet: false,
                fab:false,
                panel:[],
            }
        },
        created(){
            this.panel = [...Array(this.value.info.details.length).keys()];
        },
        /*watch:{
            selection(value){
                console.log(value);
                $vuetify.goTo(target);
            }
        },*/
        mounted(){
            setTimeout(() => {
                if (this.gotoX) {
                    var tmp = parseGotoText(this.gotoX);
                    if (tmp){
                        this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                        this.$vuetify.goTo('#id-' + tmp.controlId);
                    }
                }
            }, 300);
            this.$watch('gotoX', this.gotoXWatcher);
        },
        computed: {
            ...mapGetters([
                'storedHistory',
                'colorSet',
            ])
        },
        methods:{
            ...mapMutations([
                'addStoredHistory',
            ]),
            gotoXWatcher(newValue){
                //console.log('gotoXWatcher:', newValue);
                var tmp = parseGotoText(newValue);
                if (tmp){
                    this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                    this.$vuetify.goTo('#id-'+ tmp.controlId, {offset:100});
                }
            },
            nomalizeText(text){
                return nomalizeText(text);
            },
            async chatWithShop(){
                console.log('chatWithShop');
                if (!firebaseAuth.currentUser) {
                    console.log('you must login first');
                    this.common();
                } else {
                    console.debug('this.value: ', this.value);
                    var userId = firebaseAuth.currentUser.uid;
                    var displayName = firebaseAuth.currentUser.displayName;
                    var shopId = this.value.info.id;
                    var r_find = await DataService.findChatRoom(userId, shopId);
                    var roomId = null;
                    if(r_find.data.room){
                        roomId = r_find.data.room.id;
                    }
                    console.log('roomId: ', roomId);
                    if(!roomId){//room not found
                        var room_name = `Liên hệ với ${this.value.info.displayName}`;
                        console.log('room_name: ', room_name)
                        var r_create = await DataService.createChatRoom(userId, shopId, null, this.value.info.staffs, room_name);
                        if(r_create)
                            roomId = r_create.data.roomId;
                    }
                    console.log('roomId: ', roomId);
                    if (roomId) {
                        this.$router.push({
                            name: "Chat",
                            params: { roomId: roomId, roomname: room_name, nickname: displayName }
                        });
                    }
                }
            },
            isMobile(){
                var isMobile = true;
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': isMobile = true; break;
                    case 'sm': isMobile = true; break;
                    case 'md': isMobile = false; break;
                    case 'lg': isMobile = false; break;
                    case 'xl': isMobile = false; break;
                }
                return isMobile;
            },
            setPanel(index){
                if(this.panel.indexOf(index) == -1)
                    this.panel.push(index);
            },
            calImageHeight2(){
                var height = "300px";
                switch (this.$vuetify.breakpoint.name) {
                    //case 'xs': height = "300px"; break;
                    //case 'sm': height = "400px"; break;
                    case 'xs': height = "auto"; break;
                    case 'sm': height = "auto"; break;
                    case 'md': height = "450px"; break;
                    case 'lg': height = "450px"; break;
                    case 'xl': height = "450px"; break;
                }
                return height;
          },
        }
    }
</script>
<style>
    .v-bottom-navigation--fixed {position: fixed !important;}
    .v-item-group.v-bottom-navigation .v-btn {height: inherit !important;}
    .speed-dial-padding {right: 64px;}
</style>