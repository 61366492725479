<template>
  <div class="home">
    
      <!--<v-app-bar absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">-->
        <v-app-bar fixed color="white" shrink-on-scroll short fade-img-on-scroll scroll-target="#scrolling-techniques-7" height="56px">
          
          <template v-if="isMobile()">
            <v-app-bar-nav-icon @click.stop="nav_drawer = !nav_drawer"></v-app-bar-nav-icon>
            <template v-if="!is_input_keyword">
              <v-toolbar-title class="py-3"><span class="lokali_title"><span class="blue-grey--text darken-2">lo</span><span
                    class="orange--text">k</span><span class="blue-grey--text darken-2">ali</span>
                </span></v-toolbar-title>
              <v-spacer></v-spacer>
              <template v-if="menu_options[menu_group] == 'LOCAL_SHOP'">
                <v-btn icon @click="is_input_keyword = true;" v-if="!is_input_keyword">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </template><template v-else>
              <v-text-field solo single-line hide-details="true" v-model="keyword" hint="Từ khóa tìm kiếm"
                max-width="500px" class="pt-0 pr-1">
                <template v-slot:label>
                  <span class="text-caption">Từ khóa (tiếng Việt có dấu)</span>
                </template>
              </v-text-field>
              <v-btn icon @click="searchKeyword();" v-if="keyword != ''">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn icon @click="getAllStore();" v-else class="pl-1">
                <v-icon>mdi-select-all</v-icon>
              </v-btn>
              <v-btn icon @click="is_input_keyword = false;">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-else>
            <v-row align="center" class="ma-0">
              <v-app-bar-nav-icon @click.stop="nav_drawer = !nav_drawer"></v-app-bar-nav-icon>
              <v-col cols="2" md="2" class="text-left">
                <span class="ml-4 lokali_title"><span class="blue-grey--text darken-2">lo</span><span
                    class="orange--text">k</span><span class="blue-grey--text darken-2">ali</span>
                </span>
              </v-col>
              <v-col cols="5" md="5" class="pa-0 ma-0">
              </v-col>
              <template v-if="menu_options[menu_group] == 'LOCAL_SHOP'">
                <v-col cols="4" md="4" class="ma-0 pa-0 text-left">
                  <v-row>
                    <v-text-field solo single-line hide-details="true" v-model="keyword" hint="Hãy nhập tiếng Việt có dấu" label="Từ khóa (tiếng Việt có dấu)">
                    </v-text-field>
                    <v-btn icon @click="searchKeyword();" v-if="keyword != ''" class="pl-1">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn icon @click="getAllStore();" v-else class="pl-1">
                      <v-icon>mdi-select-all</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </template>
              <!--<v-col cols="3" md="3" class="pa-0 ma-0">
              </v-col>-->
            </v-row>
          </template>
        
          <!--
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
              
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>-->
        </v-app-bar>
      <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
        <v-sheet height="64px"></v-sheet>
          <template v-if="location">
          <template v-if="menu_options[menu_index] == 'LOCAL_NEWS'">
            <v-sheet class="py-2">
            <span class="py-2">Cập nhật tin tức</span>
            <LocalNews :geo_loc="location"/>
          </v-sheet>
          </template>
          <template v-if="menu_options[menu_index] == 'LOCAL_SHOP'" >
            <v-sheet class="py-4">
            <span v-if="searchCondition[0]" class="py-2">Tìm kiếm với từ khóa <span class="font-weight-bold">{{searchCondition[0]}}</span></span>
            <span v-if="!searchCondition[0]" class="py-2">Các cửa hàng quanh bạn </span>
            <SearchResult :query="searchCondition" />
          </v-sheet>
          </template>
        </template>
      </v-sheet>
      <v-navigation-drawer v-model="nav_drawer" app temporary width="320">
        <v-list nav dense>
          <v-list-item-group v-model="menu_group" active-class="deep-purple--text text--accent-4" class="text-left">
            <v-list-item >
              <v-list-item-title>
                <template v-if="location"><span class="font-italic">{{town_select}} - {{province_select}}</span> >> Thay đổi</template>
                <template v-else>Chưa chọn vị trí
                  <v-icon small color="orange" class="pl-2">mdi-alert-outline</v-icon>
                </template>
              </v-list-item-title>
            </v-list-item>
            <!--<v-list-item>
              <v-list-item-title>Tin tức đề xuất</v-list-item-title>
            </v-list-item>-->
            <v-list-item>
              <v-list-item-title>Tìm hiểu khu vực quanh bạn</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Cửa hàng quanh bạn</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <!--<v-sheet class="px-2 py-6" color="rgba(236, 239, 241,0.5)" outlined>
        <v-row align="center" justify="center" class="mx-2">
          <v-col cols="8" md="8" class="text-left"><span class="pr-4">{{town_select}} - {{province_select}}</span></v-col>
          <v-col cols="4" md="4">
            <v-btn @click="selectLocation = true;" text outlined>Thay đổi</v-btn>
          </v-col>
          <v-col cols="8" md="8">
            <v-text-field single-line hide-details="true" v-model="keyword" hint="Từ khóa tìm kiếm" label="Từ khóa"
              class="pt-0">
            </v-text-field>
          </v-col>
          <v-col cols="4" md="4">
            <v-btn text outlined @click="searchKeyword();" :disabled="keyword == ''">Tìm kiếm</v-btn>
          </v-col>
        </v-row>
      </v-sheet>-->
      

    <v-dialog v-model="selectLocation"  persistent class="pa-0 ma-0 " origin align="center" justify="center">
      <v-card flat tile class="ma-0 pa-4 location_dialog" max-width="600px" >
        <v-card-title class="text-subtitle-1 text-left">
        <span>Hãy chọn địa điểm bạn quan tâm</span>
      </v-card-title>
      <v-card-text class="text-caption text-left">
        <span>(Hiện tại đang giới hạn một số địa điểm, lokali sẽ nhanh chóng mở rộng và cập nhật)</span>
      </v-card-text>
        <v-row class="py-2 px-4">
          <v-col cols="12" md="12" >
            <v-combobox hide-details="true" v-model="province_select" :items="province_list" label="Tỉnh thành" outlined dense></v-combobox>
          </v-col>
          <v-col cols="12" md="12" >
            <v-combobox hide-details="true" v-model="town_select" :items="town_list" label="Quận, Huyện, Thành phố" outlined dense></v-combobox>
          </v-col>
        </v-row>
        <v-card-actions class="py-2">
          <v-row align="center" justify="center">
            <v-col cols="6" md="6">
              <v-btn @click="applyLocation(); menu_group = 1;" class="px-4 py-2">Đồng ý</v-btn>
            </v-col>
            <v-col cols="6" md="6" v-if="location || searchCondition">
              <v-btn v-if="location || searchCondition" @click="selectLocation = false; menu_group = 1;" class="px-4 py-2">Hủy</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import LocalNews from '@/components/LocalNews.vue'
import SearchResult from '@/components/SearchResult.vue'
import {loc_provinces, loc_towns} from '../utils/prefectures_towns.js'
import {getClientLocation, setClientLocation} from '../utils/cookie_utils.js'
export default {
  name: 'Home',
  components: {
    LocalNews,
    SearchResult,
  },
  data: ()=>({
    is_input_keyword: false,
    selectLocation: false,
    province_select: '',
    province_list: [],
    //town_list: null,
    town_select:'',
    location: null,
    keyword:'',
    searchCondition: null,
    nav_drawer:false,
    is_mobile:false,
    menu_group: null,
    menu_index:null,
    menu_options: {0:"CHANGE_LOC", 1:"LOCAL_NEWS", 2:"LOCAL_SHOP"}
  }),
  watch: {
        menu_group() {
          this.nav_drawer = false;
          //console.log('group:', this.menu_group);
          if (this.menu_group != null) {
            this.menu_index = this.menu_group;
            switch (this.menu_options[this.menu_index]) {
              case "CHANGE_LOC":
                //console.log('watch menu_group: CHANGE_LOC');
                this.is_input_keyword = false;
                this.keyword = "";
                this.selectLocation = true;
                break;
              case "LOCAL_NEWS":
                //console.log('watch menu_group: LOCAL_NEWS');
                this.is_input_keyword = false;
                this.keyword = "";
                if (!this.location) {
                  this.selectLocation = true;
                }
                break;
              case "LOCAL_SHOP":
                //console.log('watch menu_group: LOCAL_SHOP');
                if (!this.location) {
                  this.selectLocation = true;
                } else {
                  if (!this.searchCondition) {
                    this.searchCondition = [this.keyword, this.location];
                  }else{
                    //console.log('this.keyword:', this.keyword);
                    this.$set(this.searchCondition, 0, this.keyword);
                  }
                }
                break;
              default: break;
            }
          }
        },
      },
  mounted(){
    this.init();

    switch (this.$vuetify.breakpoint.name) {
      case 'xs': this.is_mobile = true; break;
      case 'sm': this.is_mobile = true; break;
      case 'md': this.is_mobile = false; break;
      case 'lg': this.is_mobile = false; break;
      case 'xl': this.is_mobile = false; break;
    }

    //Get cookies
    var location_cookie = getClientLocation(this.$cookie);
    if (location_cookie){
      this.location = location_cookie["geo_loc"];
      this.province_select = location_cookie["loc_place"]["p"];
      this.town_select = location_cookie["loc_place"]["t"];
      //console.log('cookies location:', this.location);
    }

    this.is_input_keyword = false;
    this.menu_group = 1;
  },
  computed:{
    town_list: function () {
      if (this.province_select != '') {
        var town_list_ = Object.keys(loc_towns[this.province_select]);
        //console.log(town_list_);
        return town_list_;
      }
      return [];
    }
  },
  methods:{
    async init(){
      await Promise.all(loc_provinces.map(element => {
        this.province_list.push(decodeURI(element));
      }));
      //console.log(this.province_list);
    },
    changeLocation(){
      this.selectLocation = true;
    },
    applyLocation(){
      this.selectLocation = false;
      this.keyword = '';
      this.location = loc_towns[this.province_select][this.town_select];
      this.searchCondition = [this.keyword, loc_towns[this.province_select][this.town_select]];
      //console.log('province_select:', this.province_select);
      /*if (this.keyword == ''){
        this.searchCondition = null;
        this.location = loc_towns[this.province_select][this.town_select];
      }
      else{
        this.location = null;
        this.searchCondition = [this.keyword, loc_towns[this.province_select][this.town_select]];
      }*/
      //console.log('this.location:',this.location);

      //Save to cookies
      setClientLocation(this.$cookie, {"geo_loc": this.location,"loc_place":{"p":this.province_select, "t":this.town_select,"a":""}});
      //this.$cookie.set('location', JSON.stringify({"geo_loc": this.location,"loc_place":{"p":this.province_select, "t":this.town_select,"a":""}}), { expires: '2M' });
    },
    searchKeyword(){
      //this.location = null;
      if (!this.searchCondition){
        this.searchCondition = [this.keyword, loc_towns[this.province_select][this.town_select]];
      }else{
        this.$set(this.searchCondition, 0, this.keyword);       
      }
      return 0;
    },
    getAllStore() {
      if (!this.location) {
        this.selectLocation = true;
      } else {
        this.keyword = '';
        if (!this.searchCondition) {
          this.searchCondition = [this.keyword, this.location];
        } else {
          this.$set(this.searchCondition, 0, this.keyword);
        }
      }
    },
    isMobile() {
      var isMobile = true;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': isMobile = true; break;
        case 'sm': isMobile = true; break;
        case 'md': isMobile = false; break;
        case 'lg': isMobile = false; break;
        case 'xl': isMobile = false; break;
      }
      return isMobile;
    },
  },
}
</script>
<style>
  .keyword_div{
    max-width: 60%;
    max-height: 60%;
  }
  .app_bar {
    max-height: 40px;
  }.location_dialog{
    position: fixed;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
  }
</style>

