<template>
  <div>
    <!-- Use the component in the right place of the template -->
    <v-container v-if="content" class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="12" class="text-left">
          <v-text-field v-model="content.title" label="Tiêu đề " required :rules="[rules.required]"
                      hint="Nhập tiêu đề bài viết"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="text-left">
          <v-text-field v-model="content.description" label="Giới thiệu nội dung" counter="100"
                      hint="Nhập giới thiệu ngắn gọn nội dung (tối đa 100 kí tự)"></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-radio-group v-model="avatar_option" row>
            <v-radio label="Dùng url sẵn có" value="url_avatar"></v-radio>
            <v-radio label="upload hình ảnh" value="image_avatar"></v-radio>
          </v-radio-group>
          <v-text-field v-if="avatar_option == 'url_avatar'" v-model="content.avatar_link" label="Url ảnh minh họa"
            hint="Nhập url hình ảnh minh họa"></v-text-field>
          <v-file-input v-if="avatar_option == 'image_avatar'" v-model="post_cover_image" show-size counter
            label="Upload ảnh minh họa"></v-file-input>
        </v-col>
        <v-col cols="12" md="12" class="text-left tiptap-edit" >
          <div @contextmenu.prevent="qdef_tool" id="txt_content" ref="txtcontent">
          <tiptap-vuetify v-model="post_content" :extensions="extensions" :toolbar-attributes="{ color: 'yellow' }" min-height="250px" max-height="600px"/>
        </div>
        </v-col>
        <v-col cols="12" md="12" class="text-left">
          <v-btn text outlined @click="preview(post_content)">Kiểm tra</v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card min-height="280px" class="d-flex flex-column">
            <div class="blue-grey lighten-4">
            <v-card-title>
              Xem lại nội dung
            </v-card-title>
            </div>
          <div id="preview_div" class="text-left pa-4">
            <v-sheet v-html="fined_html">
            </v-sheet>
          </div>
        </v-card>
        </v-col>
        <v-col cols="12" md="12">
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="12" md="12">
          <v-checkbox v-model="youtubeVideo" label="Thêm youtube video"></v-checkbox>
        </v-col>
        <v-col cols="12" md="12" class="text-left" v-if="youtubeVideo">
          <v-row align="center" class="pb-2">
            <v-col cols="5" md="5">
              <v-text-field v-model="youtubeContent.youtubeId" solo dense hide-details="true" label="ID của youtube video"
                required :rules="[rules.required]" hint="Nhập id của video youtube"></v-text-field>
            </v-col>
            <v-col cols="2" md="2" >
              <v-combobox v-model="youtubeContent.src_lang" :items="languages" hide-details="true" label="Ngôn ngữ" solo dense>
              </v-combobox>
            </v-col>
            <v-col cols="4" md="4">
              <v-btn text outlined @click="getYoutube();">Lấy phụ đề của video</v-btn>
            </v-col>
          </v-row>
          <span v-if="this.getYoutubeCaptionStatus == 0">Có lỗi xảy ra, hãy kiểm tra lại ID</span>
          <span v-if="this.getYoutubeCaptionStatus == 1">Phụ đề hiển thị bên dưới, hãy dịch sang ngôn ngữ của bạn.</span>
          <span v-if="this.getYoutubeCaptionStatus == 2">Không tìm thấy phụ đề. Hãy kiểm tra lại ngôn ngữ; hoặc tự nhập phụ đề  <a href="#" @click="isManualTranscript = true;">(trợ giúp)</a>.</span>
          <v-spacer></v-spacer>
          <v-textarea id="video_subtitle" name="input-7-1" label="Phụ đề" rows="15" no-resize v-model="youtubeContent.transcript" @contextmenu.prevent="qdef_tool('video_subtitle')">
          </v-textarea>
          <v-btn text outlined @click="prepareTranscript();">Xem lại phụ đề</v-btn>
          <div v-if="ytErrorList && ytErrorList.length > 0" class="pa-4">
            <span>Có <span class="red--text">{{ytErrorList.length}}</span> lỗi, vui lòng xem và sửa lại</span><br/>
            <span class="red--text" v-for="(err, errIdx) in ytErrorList" :key="errIdx">
              {{err}}
              <br/>
            </span>
          </div>
          <div class="transcript-content text-left mt-2">
            <v-list class="text-left">
              <v-list-item two-line v-for="(item, index) in youtubeContent.transcript_data" :key="index">
                <v-list-item-content class="py-0">
                  <span>
                    {{getTimeLine(item.start)}}
                  </span>
                  <div v-html="item.caption"></div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <!--<v-btn @click="saveVideo(youtubeContent);">Luu</v-btn>-->
        </v-col>
        <v-col cols="12" md="12" class="pa-0">
        <v-spacer></v-spacer>
          <v-card-actions class="pa-4 text-right d-flex align-end">
            <v-btn @click="saveContent(content);" class="blue-grey lighten-4">{{actionName}}</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :timeout="timeout" light class="text-center">
        {{actionResult}}
      </v-snackbar>
    </v-container>
    <v-dialog v-model="isManualTranscript" transition="dialog-bottom-transition" persistent max-width="800px">
      <v-card class="pa-4">
        <v-textarea name="input-7-1" label="Phụ đề" rows="15" no-resize v-model="manualTranscript"></v-textarea>
        <v-btn text outlined @click="getManualTs();">Lấy phụ đề</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="useQuickDef" transition="dialog-bottom-transition" persistent max-width="800px">
      <template v-if="useQuickDef">
        <qdeftool v-model="qdefObject" :text="qdefText"></qdeftool>
      </template>
      <!--<v-card class="pa-4">
        <v-list class="text-left">
          <v-list-item two-line v-for="(item, index) in qdefCandidates" :key="index">
            <v-list-item-content class="py-0">
              {{item.title}}
            </v-list-item-content>
            <v-list-item-content class="py-0">
              {{item.queries}}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>--> 
    </v-dialog>
  </div>
</template>

<script>
  //import { mdiCamera } from '@mdi/js'
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, 
    Link, Blockquote, HardBreak, HorizontalRule, History, Image, Table, TableCell, TableHeader, TableRow } from 'tiptap-vuetify'
  import QdefTool from '../business/QdefTool.vue'
  import DataService from '../../services/DataService.js'
  import {quickdef, convert_quickdef} from '../../utils/text_utils.js'
  export default {
    name: 'ContenEditor',
    // specify TiptapVuetify component in "components"
    components: { 
      TiptapVuetify,
      'qdeftool': QdefTool
    },
    data: () => ({
      shopId: null,
      content: null,
      snackbar: false,
      actionResult: '',
      timeout: 3000,
      contentId: null,
      rules: {
        required: value => !!value || 'Trường bắt buộc.',
        min: value => (value && value.length >= 3) || 'Ít nhất 3 kí tự',
      },
      post_title: null,
      post_type: null,
      post_cover_image: null,
      // declare extensions you want to use
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3, 4, 5],
          },
        }],
        [Image, {
          /*options: {
            imageSources: [{ component: FileSelector, name: 'File Selector' }],
          },*/
        }],
        Bold,
        Code,
        HorizontalRule,
        HardBreak,
        Paragraph,
        /*TodoList,
        [TodoItem, {
          options: {
            nested: true
          }
        }],*/
        Table,
        TableCell,
        TableHeader,
        TableRow,
      ],
      post_content: '',
      fined_html: '',
      avatar_option: 'url_avatar',
      youtubeVideo: false,
      getYoutubeCaptionStatus: -1,
      youtubeContent: {id:"", youtubeId:"", transcript: "", transcript_data: [], src_lang: "en", dest_lang: "vi"},
      languages:['en','fr','de','zh','ja','ko','vi'],
      ytErrorList: [],
      isManualTranscript: false,
      manualTranscript: "",
      useQuickDef:null,
      //qdefCandidates:[],
      qdefText:'',
      qdefObject:null,
      qdefControlId:null,
    }),
    watch: {
      post_content: async function(){
        this.fined_html = '';
      },
      qdefObject: function(newVal){
        //console.log("newVal:",newVal);
        if (newVal){
          if (newVal == -1 || newVal == 1){
            this.useQuickDef = null;
          }else{ // not use now because risk in replace within textarea
            console.log("insert loka qdef");
            this.replaceSelectedText(this.qdefText + " @LOKAQDEF" + newVal.dbId + "#["+ newVal.language +"] LOKAQDEF@");
          }
          this.useQuickDef = null;
          this.qdefObject = null;
          return false;
        }
      },
    },
    mounted(){
      this.shopId = this.$route.params.shopId;
      if (this.$route.query.news){
        this.content = this.$route.query.news;
        this.setPostContent(this.content.content);

        //get video if exist
        if (this.content.videoId){
          this.getVideo(this.content.videoId);
          this.youtubeVideo = true;
        }
      }else{
        this.content = {'title':'','avatar':'', 'content':''};
      }
    },
    computed:{
      actionName(){
        if (this.content.id){
          return 'Cập nhật nội dung';
        }else{
          return 'Tạo mới nội dung';
        }
      },
    },
    methods: {
      blobToData: (blob) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        })
      },
      async saveContent(item){
        //Save youtube video first
        var videoId = null;
        if (this.youtubeVideo){
          videoId = await this.saveVideo(this.youtubeContent);
        }

        var result = null;
        //console.log('saveContent(item)', item);
        if (this.post_cover_image) {
          item.avatar = await this.blobToData(this.post_cover_image)
        }
        var html_content = this.post_content;
        html_content = await this.processInline(html_content);
        html_content = await this.processQuickdef(html_content);
        item.content = html_content;
        if (item.id){ //update news
          var id_ = item.id;
          var tmp = item;
          if (tmp.created_date){
            delete tmp.created_date;
          }
          if (tmp.modified_date){
            delete tmp.modified_date;
          }
          //console.log("update news:", tmp);
          result = await DataService.updateNews(this.shopId, id_, tmp).then(function(){
            return true;
          }).catch(function(){
            return false;
          });
        }else{// add news
          //add youtube video
          if (videoId){
            item['videoId'] = videoId;
          }

          result = await DataService.addNews(this.shopId, item).then(function(){
            return true;
          }).catch(function(){
            return false;
          });
        }
        if (result){
          this.snackbar = true;
          this.actionResult = 'Đã lưu thành công';
          return this.backToNewslist();
        }
        else{
          this.snackbar = true;
          this.actionResult = 'Lưu thất bại. Vui lòng xem lại';
        }
      },
      backToNewslist() {
        this.$router.push({
          name: "NewsList"
        });
      },
      async processQuickdef(content){
        var inlines = content.match(/@LOKAQDEF.*?LOKAQDEF@/g);
        if (inlines && inlines.length > 0){
          await inlines.forEach(element => {
            var inline_text = element.replace('@LOKAQDEF','').replace('LOKAQDEF@','');
            var html_text = quickdef(inline_text);
            content = content.replace(element, html_text);
          })
        }
        return content;
      },
      async processInline(content){
        //processing_status = true;
        var inlines = content.match(/@INLINE.*?INLINE@/g);
        if (inlines && inlines.length > 0){
          await inlines.forEach(element => {
            var inline_text = element.replace('@INLINE','').replace('INLINE@','');
            var html_text = this.htmlDecode(inline_text);
            content = content.replace(element, html_text);
          })
        }
        //processing_status = false;
        //result = content;
        return content;
      },
      htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
      },
      async preview(content){
        var html_content = content;
        html_content = await this.processInline(html_content);
        html_content = await this.processQuickdef(html_content);
        this.fined_html = html_content;
      },
      async setPostContent(content){
        const tmp_ = await this.getQdef(content);
        this.post_content = tmp_;
      },
      async getQdef(content){
        var qdefs = content.match(/<a .*?data-modal="#modal".*?>.*?<\/a>/g);
        if (qdefs && qdefs.length > 0){
          await qdefs.forEach(element => {
            var qdef_text = convert_quickdef(element);
            content = content.replace(element, qdef_text);
          })
        }
        return content;
      },

      async getVideo(videoId){
        console.log("get video:", videoId)
        var result = await DataService.getVideo(videoId).then(function (data) {
          return data.data.result;
        }).catch(function (err) {
          console.log(err);
          return null;
        });
        //console.log("get video rs:", result);
        if (result){
          var tsData = JSON.parse(result.transcript_data);
          var tsString = '';
          for (const element of tsData){
            //console.log("element:", element);
            var aline = await this.showTranscript(element);
            tsString += aline;
          }
          result.id = videoId;
          result.transcript = tsString;
          result.transcript_data = tsData;
          this.youtubeContent = result;
        }
      },
      async getYoutube() {
       var result = await DataService.getTranscript(this.youtubeContent.youtubeId, this.youtubeContent.src_lang).then(function (data) {
          return data;
        }).catch(function (err) {
          console.log(err);
          return null;
        });
        console.log("get caption result:", result);
        if (result) {
          if (result.data != "") {
            this.youtubeContent.transcript = "";
            var text = result.data;
            if (text) {
              text = text.split('\n').join(' ');
              //console.log(text);
              var inlines = text.match(/<text .*?<\/text>/g);
              if (inlines && inlines.length > 0) {
                for (const element of inlines) {
                  var tmp_ = element.match(/<text start="([0-9]*\.?[0-9]*)" dur="([0-9]*\.?[0-9]*)">(.*?)<\/text>/);
                  //console.log(tmp_);
                  if (tmp_ && tmp_.length == 4) {
                    this.youtubeContent.transcript += `[${tmp_[1]}]: ${tmp_[3]}\n`;
                  }
                }
              }
            }
            this.getYoutubeCaptionStatus = 1;
          }else{//youtube id is ok but no caption
            this.getYoutubeCaptionStatus = 2;
          }
        }else{//error when get caption
          this.getYoutubeCaptionStatus = 0;
        }
      },
      async prepareTranscript(){
        this.youtubeContent.transcript_data = [];
        var lines = this.youtubeContent.transcript.split("\n");      
        if (lines && lines.length > 0){
          this.ytErrorList = [];
          for (const element of lines){
            if (element != ""){
              const item = await this.parseYTtranscript(this.escapeHtml(element));
              if (item){
                this.youtubeContent.transcript_data.push(item);
              }else{
                this.ytErrorList.push(element);
              }
            }
          }
        }
      },
      async parseYTtranscript(ytscript){
        if (ytscript){
        var tmp_ = ytscript.match(/\[([0-9]+\.?[0-9]*)\]: ?(.*)/);
          if (tmp_ && tmp_.length == 3) {
            const content = await this.processQuickdef(tmp_[2]);
            return { start: parseFloat(tmp_[1]), caption: content };
          }
          else {
            return null;
          }
        }else{
          return {};
        }
      },
      async showTranscript(item){
        try {
          if (item && item["caption"]) {
            var content = await this.getQdef(item["caption"])
            return `[${item["start"]}]: ${content}\n`;
          } else {
            return 'NULL';
          }
        } catch{
          return 'NULL';
        }
      },
      getManualTs(){
        var tmp_ = this.parseRawTs(this.manualTranscript);
        if (tmp_){
          this.youtubeContent.transcript = tmp_;
        }
        this.manualTranscript = "";
        this.isManualTranscript = false;
      },
      parseRawTs(text){
        var lines = text.split("\n");
        var time_ = -1;
        var caption_ = "";
        var finedTs = "";
        if (lines && lines.length > 0) {
          for (const element of lines) {
            if (element != "") {
              if (time_ == -1) {//parse time by format xx:yy
                var tmp_ = element.match(/^([0-9][0-9]):([0-9][0-9]).*?/)
                if (tmp_ && tmp_.length == 3) {
                  time_ = parseInt(tmp_[1]) * 60 + parseInt(tmp_[2]);
                }
              } else {//parse caption
                caption_ = element;
              }
              if (time_ >= 0 && caption_ !== ""){
                finedTs += `[${time_}]: ${caption_}` + '\n';
                time_ = -1;
                caption_ = "";
              }
            }
          }
        }
        return finedTs;
      },
      getTimeLine(seconds) {
        try {
          var minute = Math.floor(Math.round(seconds) / 60);
          var second = Math.round(seconds) % 60;
          return String(minute).padStart(2, '0') + ":" + String(second).padStart(2, '0');
        } catch{
          return ""
        }
      },
      async saveVideo(item){
        var id_ = null;
        //await this.prepareTranscript();
        //var ts = Object.fromEntries(item.transcript_data);
        var ts = JSON.stringify(item.transcript_data);

        if (item){
          if(item.id){ //update video
            console.log("UPDATE VIDEO");
            var tmp_ = {};
            tmp_["transcript_data"] = ts
            const updatedResult = await DataService.updateVideo(item.id, tmp_).then(function (data) {
              return data;
            }).catch(function () {
              return null;
            });
            console.log(updatedResult);
            id_ = item.id;
          }else{ //add new video
            console.log("ADD VIDEO");
            const tmp_ = {};
            tmp_["youtubeId"] = item.youtubeId;
            tmp_["src_lang"] = item.src_lang;
            tmp_["dest_lang"] = item.dest_lang;
            tmp_["transcript_data"] = ts
            console.log(tmp_);
            const addedId = await DataService.addVideo(tmp_).then(function (data) {
              return data.data.result;
            }).catch(function () {
              return null;
            });
            id_ = addedId;
          }
        }
        console.log("saveVideo result:", id_);
        return id_;
      },
      escapeHtml(text) {
        var map = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#039;'
        };

        return text.replace(/[&<>"']/g, function (m) { return map[m]; });
      },

      async qdef_tool(controlId){//event){
        //console.log("event:", event);
        this.qdefControlId = controlId;
        const text_ = window.getSelection().toString();
        if (text_){
          //console.log(text_);
          this.qdefText = text_;
          this.useQuickDef = true;
          return false;
        }else{
          return true;
        }
      },
      replaceSelectedText(replacementText) {
        var sel, range;
        if (this.qdefControlId){
          var txtarea = document.getElementById(this.qdefControlId);
          console.log("txtarea:",txtarea);
          var len = txtarea.value.length;
          // obtain the index of the first selected character
          var start = txtarea.selectionStart;
          // obtain the index of the last selected character
          var finish = txtarea.selectionEnd;
          // obtain the selected text
          //var sel = txtarea.value.substring(start, finish);
          // do something with the selected content
          console.log("start:", start, ", finish:", finish);
          var tmp_ =  txtarea.value.substring(0,start) + replacementText + txtarea.value.substring(finish,len);
          txtarea.focus();
          txtarea.value = tmp_;
        } else {
          if (window.getSelection) {
            sel = window.getSelection();
            console.log("sel:", sel);
            if (sel.rangeCount) {
              range = sel.getRangeAt(0);
              console.log("range:", range);
              range.deleteContents();
              range.insertNode(document.createTextNode(replacementText));
            }
          } else if (document.selection && document.selection.createRange) {
            range = document.selection.createRange();
            console.log("range doc:", range);
            range.text = replacementText;
          }
        }
      }
    },
  }
</script>
<style>
  #preview_div {
    width: 100%;
  }
  #preview_div img, .tiptap-edit img {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #preview_div blockquote{
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding-left: 1em;
    margin: 20px 0!important;
  }
  .transcript-content{
        max-height: 400px;
        overflow: scroll;
        font-size: 14px;
        font-weight: 350;
    }
</style>