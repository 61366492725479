<template>
    <div>
        <v-card class="pa-4">
            <v-text-field v-model="searchText" label="Nhập keyword"
            hint="Nhập keyword"></v-text-field>
            <v-btn text outlined @click="search(searchText);" :disabled="isSearching">Tìm kiếm</v-btn>
            <template v-if="isSearching">
                <div>
                    <v-card-subtitle>Đang tìm kiếm dữ liệu. Vui lòng chờ trong giây lát.</v-card-subtitle>
                </div>
            </template>
            <template v-else>
                <template v-if="qdefCandidates.length == 0">
                    <v-card-subtitle>Không tìm thấy dữ liệu phù hợp. Bạn vui lòng thử lại với keyword khác.</v-card-subtitle>
                </template>
                <template v-else>
                    <div class="text-left py-2 px-4 red--text">
                        <span>Có <span>{{qdefCandidates.length}}</span> kết quả.</span>
                    </div>
                    <div class="content_list">
                        <v-list class="text-left" v-if="qdefCandidates">
                            <v-list-item-group v-model="selectedItem" color="primary">
                                <v-list-item three-line v-for="(item, index) in qdefCandidates" :key="index" class="py-1">
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title>{{item.title}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.dbId}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>{{item.queries}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </div>
                </template>
            </template>
            <v-card-actions class="pa-4 text-right d-flex align-end">
                <v-btn @click="inputVal = -1;" class="blue-grey lighten-4">Hủy bỏ</v-btn>
                <v-btn @click="inputVal = copyToClipboard(qdefCandidates[selectedItem]);" class="blue-grey lighten-4" :disabled="!(selectedItem >= 0)">Sao chép vào clipboard</v-btn>
              </v-card-actions>
        </v-card>
    </div>
</template>
<script>
    import DataService from '../../services/DataService.js';
    export default {
        props: ['value','text'],
        name: "QdefTool",
        data: () => ({
            qdefCandidates:[],
            selectedItem: -1,
            searchText:'',
            isSearching:false,
        }),
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            },
        },
        mounted() {
            if (this.text){
                this.searchText = this.text;
                this.search(this.searchText);
            }
        },
        
        methods: {
            async search(text) {
                this.isSearching = true;
                const queryResult_ = await DataService.searchQdefContent(text + "%20");
                if (queryResult_) {
                    this.qdefCandidates = queryResult_.data.result.hits;
                    //console.log(this.qdefCandidates);
                }
                this.isSearching = false;
                return;
            },
            copyToClipboard(item){
                var qdef = "@LOKAQDEF" + item.dbId + "#[" + item.language + "]LOKAQDEF@ ";
                navigator.clipboard.writeText(qdef);
                return 1;
            }
        },
    }
</script>
<style>
    .content_list{
        max-height: 400px;
        overflow: scroll;
        font-size: 14px;
    }
</style>
