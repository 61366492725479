<template>
<v-container class="text-center pa-0 ma-0">
  <template v-if="itemContent != null">
  <div class="white quickview_layout">
    <div class="pa-4 text-right">
    
    <v-btn class="px-4 text-lowercase font-italic text-body-1" color="blue-grey" @click="gotoOrg()" text outlined>
      >> {{getOrgGreeting(globalShopId)}}
    </v-btn>
  </div>
    <template v-if="itemType == 'prod'">
      <product-detail :product="itemContent"></product-detail>
    </template>
    <template v-if="itemType == 'news'">
      <news-detail :news="itemContent"></news-detail>
    </template>
    <div v-if="similarContents && similarContents.length > 0" class="py-2">
      <span style="font-size: 20px;">Nội dung tương tự</span>
      <v-list>
        <template v-if="itemType == 'prod'">
          <v-row>
            <v-col cols="12" md="6" v-for="product in similarContents"  :key="product.id">
          <v-list-item three-line @click="openContent(product.id,'prod')" >
            <v-list-item-avatar tile size="70">
                <v-img :src="product.image_link1"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left" >
                <v-list-item-title v-html="product.product_name" class="font-weight-bold"></v-list-item-title>
                <v-list-item-subtitle v-html="product.description" class="fine-description"></v-list-item-subtitle>
                <v-list-item-subtitle v-if="product.price" class="orange--text">Giá: {{product.price}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
            </v-col>
            </v-row>
        </template>
        <template v-if="itemType == 'news'">
          <v-row>
          <v-col cols="12" md="6" v-for="news in similarContents"  :key="news.id">
          <v-list-item three-line @click="openContent(news.id, 'news')" >        
            <v-list-item-avatar tile size="100">
                <v-img :src="news.avatar_link"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
                <v-list-item-title v-html="news.title" class="font-weight-bold"></v-list-item-title>
                <v-list-item-subtitle>{{news.description}}</v-list-item-subtitle>
                <v-list-item-subtitle>{{getDate(news.created_date)}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
        </template>
      </v-list>
    </div>
  </div>
</template>
</v-container>
</template>
<script>
  import ProductDetail from "../components/ProductDetail.vue";
  import NewsDetail from "../components/NewsDetail.vue";
  import DataService from "../services/DataService.js";
  import { mapGetters, mapMutations } from 'vuex';
  import {getDate} from "../utils/dates.js";
  import {fineTitle} from "../utils/text_utils.js"
  export default {
      props: ['common'],
      name: 'QuickView',
      components: {
        'product-detail': ProductDetail,
        'news-detail': NewsDetail,
        //'news-list': NewsList,
        //'fab_util': FabUtil,
        //'menu_util': MenuUtil,*/
      },
      data: () => ({
        itemContent: null,
        itemType: null,
        globalShopId: null,
        similarContents: null,
      }),
      created() {
        this.addMetaInfo('test title','welcome welcome','https://storage.googleapis.com/lokal-it-dev/images/butve_3d_sangtao/79edac033e4ccb12925d24.jpg');
      },
      mounted() {
        this.initPage();
      },
      computed: {
        ...mapGetters([
          'shopId',
          'colorSet',
          'storedHistory',
        ]),
        /*
        getNavList(){
          return this.navDic[this.tab];
        },
        getMenus(){
          var menus = [];
          menus.push({'title':this.tabName['tab_shop'], 'nav_list':this.navDic['tab_shop']});
          menus.push({'title':this.tabName['tab_product'], 'nav_list':this.navDic['tab_product']});
          menus.push({'title':this.tabName['tab_fqa'], 'nav_list':this.navDic['tab_fqa']});
          menus.push({'title':this.tabName['tab_news'], 'nav_list':this.navDic['tab_news']});
          return menus;
        },*/
      },
      methods: {
        ...mapMutations([
            'createShopId',
            'addStoredHistory',
          ]),
          initPage() {
          var shopId_ = this.$route.params.shopId;
          this.globalShopId = shopId_;
          this.itemType = this.$route.params.itemType;
          var itemId = this.$route.params.itemId;
          switch (this.itemType) {
            case 'prod': this.getProd(shopId_, itemId); break;
            case 'news': this.getNews(shopId_, itemId); break;
            case 'qa': break;
          }
          //init shopId in store session
          this.createShopId({ shopId: shopId_, theme: null });
          //Add history
          if (this.itemType != null && itemId != null) {
            this.addStoredHistory("[StartQuickview]" + this.itemType + "@" + itemId);
          } else {
            this.addStoredHistory("[StartQuickview]");
          }

          this.$vuetify.goTo(0);

          /*setTimeout(() => {
            console.log("time fired !!! this.itemContent", this.itemContent);
            if (this.itemContent) {
              switch (this.itemType) {
                case 'prod': this.getProdList(shopId_); break;
                case 'news': this.getNewsList(shopId_); break;
                case 'qa': break;
              }
            }
          }, 3000);*/
        },
        async getProd(shopId, prodId) {
          const get_result = await DataService.getProduct(shopId, prodId)
            .then(response => {
              return response.data.result;
            }).catch((e) => {
              console.log(e);
              return null;
            })
          if (get_result){
            this.itemContent = get_result;
            
            //get similar products
            this.getProdList(shopId, this.itemContent.type, this.itemContent.type2, prodId);
            this.updateWebTitle(this.itemContent.product_name);
          }
        },
        async getNews(shopId, newsId) {
          await DataService.getNews(shopId, newsId)
            .then(response => {
              this.itemContent = response.data.result;
              this.updateWebTitle(this.itemContent.title);
              
              //get similar news
              this.getNewsList(shopId, newsId);
            }).catch((e) => {
              console.log(e);
            })
        },
        async getProdList(shopId, type, type2, prodId){
          //get product list
            DataService.getSimilarProducts(shopId, type, type2, 5).then(async (result) => {
              var tmp_ = result.data.result;
              if (tmp_){
                tmp_=  tmp_.filter(item => item.id !== prodId);
                if (tmp_.length > 0){
                  this.similarContents = tmp_;
                }
              }
              //console.log('this.similarContents:', this.similarContents);
            }).catch(function (error) {
              console.log(error);
            });
        },
        async getNewsList(shopId, newsId){
          //get news list
          DataService.getNewsList(shopId).then(async (result) => {
              //console.log('result.data:',result.data);
              var tmp_ = result.data.result;
              if (tmp_){
                tmp_ = tmp_.filter(item => item.id !== newsId);
                if (tmp_.length > 0){
                  this.similarContents = tmp_;
                }
              }
              //console.log('this.similarContents:', this.similarContents);
            }).catch(function (error) {
              console.log(error);
            });
        },
        addMetaInfo(title, description, img){
            let meta_title = document.createElement('meta');
            meta_title.setAttribute('property','og:title');
            meta_title.setAttribute('content', title);

            let meta_description = document.createElement('meta');
            meta_description.setAttribute('property','og:description');
            meta_description.setAttribute('content', description);

            let meta_img = document.createElement('meta');
            meta_img.setAttribute('property','og:image');
            meta_img.setAttribute('content', img);

            document.head.append(meta_title);
            document.head.append(meta_description);
            document.head.append(meta_img);
          },
        gotoOrg(){
          return this.$router.push({
                            name: "Shop",
                            params: { id: this.globalShopId}
                        });
        },
        getOrgGreeting(orgId){
          var path = window.location.pathname;
          if (path.includes('/grp/') || path.includes('/org/')){
            return "Giới thiệu về " + orgId;
          }
          else if (path.includes('/shop/')){
            return "Vào trang " + orgId;
          }
          else{
            return "Giới thiệu về " + orgId;
          }
        },
        updateWebTitle(title) {
            if (title) {
              document.title = title;
            }
          },
        getDate(timestamp){
          return getDate(timestamp);
        },
        checkItem(idx, id) {
          if (idx < 10 && id !== this.$route.params.itemId) {
            return true;
          } else {
            return false;
          }
        },
        async openContent(id, type){
          console.log(id, type);
          this.$router.push({name:"QuickView", params: { shopId: this.globalShopId, itemType: type, itemId: id }});
          this.initPage();
        },
        niceDescription(text){
          if(text){
            return fineTitle(this.$vuetify.breakpoint.name, text, [30,30,40,40,40]);
          }
        }
      },
    }
</script>
<style>
  .text-caption{
      font-family: Tahoma, none;
    }
  .intro_text{
    font-size:14px;
    font-weight: 400;
    color: #FFFFFF !important;
  }
  .tab_title{
    text-transform: capitalize;
    font-size:16px;
  }
  .fine-description{
    max-height: 2.8em !important;
    line-height: 1.4em !important;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
  }
  .fine-description p{
    margin: 0 0 0 0;
  }
</style>
