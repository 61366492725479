<template>
    <div>
    <v-row class="pa-0 ma-0">
        <v-expansion-panels multiple v-model="panels" light flat tile>
            <v-expansion-panel v-for="(fqa, index) in fqas" :key="index" class="pa-0 ma-0">
                <v-expansion-panel-header light :id="'id-' + fqa.id" :ref="nomalizeText(fqa.question)" :color="colorSet.background" :class="colorSet.textColor" class="text-left">
                    <span class="text-capitalize expansion_panel_title">
                        <v-icon class="blue-grey--text text--lighten-5 mr-1">mdi-dots-square</v-icon>{{fqa.question}}
                    </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-left pa-3 ma-0 blue-grey lighten-5">
                    <div v-if="!fullPanels[index]" class="panel-div">
                        <template v-if="lokaTopic.includes(index) && (fqa.topics && fqa.topics.length > 0)">
                            <v-row class="pa-0 ma-0 ">
                                <v-col cols="12" md="12" class="px-0 py-2 ma-0 font-italic">Tóm tắt</v-col>
                                <v-col cols="12" md="12" class="px-0 pb-2 ma-0 grey--text text--darken-1" v-for="(topic, index) in fqa.topics.slice(0, 2)"
                                    :key="index">
                                    <span v-for="(doc, doc_idx) in topic.docs" :key="doc_idx" class="py-2">
                                        <template v-if="doc_idx < 2">{{doc.doc}}...<br />
                                        </template></span>
                                </v-col>
                                <v-col cols="12" md="12" class="px-1 py-1 text-right">
                                    <v-btn medium outlined color="orange" class="text-lowercase" @click="viewContent(index)">Xem đầy đủ</v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            <v-row class="pa-0 ma-0">
                                <v-col class="px-0 pt-0 pb-2 ma-0" cols="12" md="12">
                                    <v-sheet v-html='fqa.answer' class="py-2 transparent text--secondary briefContent">
                                    </v-sheet>
                                    <div class="overlayer"></div>
                                </v-col>
                                <v-col cols="12" md="12" class="px-1 py-2 text-right">
                                    <v-btn medium outlined color="orange" class="text-lowercase" @click="viewContent(index)">Xem đầy đủ</v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </div>
                    <div v-else class="panel-div" v-html='fqa.answer'></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
    </div>
</template>
<script>
    //import FabUtil from "../components/FabUtil.vue";
    import { mapGetters, mapMutations } from 'vuex';
    import { nomalizeText, createGotoText, parseGotoText} from "../utils/text_utils.js";
    export default {
        props: ['value','fqas', 'shopInfo','common', 'navigate','gotoX', 'leaveX'],
        components: {
            //'fab_util': FabUtil,
        },
        data: ()=>({
            panels:[],
            fab:false,
            fullPanels:[],
            initFullPanels:[],
            lokaTopic:[],
        }),
        created(){
            //console.log('[FqaList] [created]');
            
            console.log('this.gotoX: ', this.gotoX);
        },
        mounted(){
            setTimeout(() => {
                console.log('timeout fired');
                if(this.gotoX)
                    this.gotoXWatcher(this.gotoX);
                /*if (this.gotoX) {
                    var tmp = parseGotoText(this.gotoX);
                    if (tmp && tmp.controlId){
                        //console.log('[FqaList] goto:', tmp.controlId);
                        this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                        this.$vuetify.goTo('#id-' + tmp.controlId, {offset:100});
                    }
                }*/
            }, 300);
            this.$watch('gotoX', this.gotoXWatcher);
            console.log(this.fqas);
            this.panels = [...Array(Object.values(this.fqas).length).keys()];
            this.fullPanels = [...Array(Object.values(this.fqas).length).fill(false)];
            Object.assign(this.initFullPanels, this.fullPanels);
            //this.lokaTopic = [...Array(Object.values(this.fqas).length).fill(false)];
            
            /*for(var i = 0; i < this.fqas.length/2; i++){
                var ran = Math.floor(Math.random() * this.lokaTopic.length);
                if (!this.lokaTopic.includes(ran) && this.fqas[ran].topics)
                    this.lokaTopic.push(ran);
            }*/
            //debug
            this.lokaTopic = [...Array(Object.values(this.fqas).length).keys()];
        },
        computed: {
            ...mapGetters([
                'storedHistory',
                'colorSet',
            ])
        },
        watch:{
            /*panels: function (newValue) {
                console.log(newValue);
                if (newValue != null){
                    this.addStoredHistory(createGotoText('tab_fqa', this.fqas[newValue].id));
                }
            },*/
        },
        methods:{
            ...mapMutations([
                'addStoredHistory',
            ]),
            gotoXWatcher(newValue){
                //console.log('gotoXWatcher:', newValue);
                var tmp = parseGotoText(newValue);
                if (tmp && tmp.controlId){
                    this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                    this.$vuetify.goTo('#id-'+ tmp.controlId, {offset:100});
                    var index = this.fqas.findIndex(item => { return (item.id == tmp.controlId); });
                    if (index >= 0)
                        //this.panels = index;
                        this.viewContent(index);
                }
            },
            gotoContent(navText){
                var tmp = parseGotoText(navText);
                if (tmp){
                    if (tmp.tabName == 'tab_fqa'){
                        this.addStoredHistory(createGotoText("tab_fqa", tmp.controlId));
                        this.$vuetify.goTo("#id-" + tmp.controlId, {offset:100});
                    }else{
                        this.navigate(navText);
                    }
                }
            },
            nomalizeText(text){
                return nomalizeText(text);
            },
            isMobile(){
                var isMobile = true;
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': isMobile = true; break;
                    case 'sm': isMobile = true; break;
                    case 'md': isMobile = false; break;
                    case 'lg': isMobile = false; break;
                    case 'xl': isMobile = false; break;
                }
                return isMobile;
            },
            viewContent(index){
                //this.$set(this, 'fullPanels', this.initFullPanels);
                this.initFullPanels.forEach((element, e_index) => {
                    if (element != this.fullPanels[e_index] && e_index != index){
                        this.$set(this.fullPanels, e_index, element);
                    }
                });
                this.$set(this.fullPanels, index, true);
                this.addStoredHistory(createGotoText('tab_fqa', this.fqas[index].id));
            },
            
            setPanel(index){
                if(this.panels.indexOf(index) == -1)
                    this.panels.push(index);
            },
            cal_margin(index){
                if (index > 0 )
                    return "pa-0 ml-4 mr-0 my-0";
                else
                    return "pa-0 ml-0 mr-0 my-0";
            },
            calImageHeight(){
                var height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': height = "150px"; break;
                    case 'sm': height = "200px"; break;
                    case 'md': height = "250px"; break;
                    case 'lg': height = "250px"; break;
                    case 'xl': height = "250px"; break;
                }
                return height;
            },
        },
    }
</script>
<style>
    .headerClass{
        white-space: nowrap ;
        word-break: normal;
        overflow: hidden ;
        text-overflow: ellipsis;
    }
    .panel-div {
        font-size:14px;
    }
    .panel-div img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    .v-speed-dial__list {
        position: relative;
    }
    .briefContent{
        max-height: 145px; 
        line-height: 20px; 
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    .overlayer{
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        height: 160px; 
        background: -webkit-linear-gradient(to bottom, rgba(236,239,241,.0) 70%, rgba(236,239,241,0.9));
        background: linear-gradient(to bottom,  rgba(236,239,241,.0) 70%, rgba(236,239,241, 1));
    }
</style>
