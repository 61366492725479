<template>
  <div>
    <!-- Use the component in the right place of the template -->
    <v-container>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="12" class="text-left">
          <div v-html="instruction_content">
          </div>
        </v-col>
        <v-col cols="12" md="12" class="text-left tiptap-edit">
          <tiptap-vuetify v-model="post_content" :extensions="extensions" min-height="250px" max-height="600px"/>
        </v-col>
        <v-col cols="12" md="12" class="text-left">
          <v-btn @click="preview(post_content)">Kiểm tra</v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card min-height="280px" class="d-flex flex-column">
            <div class="blue-grey lighten-4">
            <v-card-title>
              Xem lại nội dung
            </v-card-title>
            </div>
          <div id="preview_div" class="text-left pa-4">
            <v-sheet v-html="fined_html">
            </v-sheet>
          </div>
          <v-spacer></v-spacer>
          <v-card-actions class="pa-4 text-right d-flex align-end">
            <v-btn @click="copyContent(); snackbar=true;" class="blue-grey lighten-4">Sao chép vào clipboard</v-btn>
          </v-card-actions>
        </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :timeout="timeout" light class="text-center">
        Đã sao chép vào clipboard !
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  //import { mdiCamera } from '@mdi/js'
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, 
    Link, Blockquote, HardBreak, HorizontalRule, History, Image, Table, TableCell, TableHeader, TableRow } from 'tiptap-vuetify'
  //import FileSelector from '../tiptap_components/FileSelector'
  import {quickdef} from '../utils/text_utils.js'
  export default {
    name: 'Admin',
    // specify TiptapVuetify component in "components"
    components: { TiptapVuetify },
    data: () => ({
      //mdiCamera: mdiCamera,
      snackbar: false,
      timeout: 3000,
      types: ['Du lịch', 'Công việc', 'IT', 'Cuộc sống', 'Khác'],
      rules: [
        value => !!value || 'Trường bắt buộc.',
        value => (value && value.length >= 3) || 'Ít nhất 3 kí tự',
      ],
      post_title: null,
      post_type: null,
      post_cover_image: null,
      // declare extensions you want to use
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3, 4, 5],
          },
        }],
        [Image, {
          /*options: {
            imageSources: [{ component: FileSelector, name: 'File Selector' }],
          },*/
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
        /*TodoList,
        [TodoItem, {
          options: {
            nested: true
          }
        }],*/
        Table,
        TableCell,
        TableHeader,
        TableRow,
      ],
      // starting editor's content
      instruction_content: '<p><strong>Hỗ trợ tạo nội dung hiển thị html</strong></p><ol><li><p>Nhập nội dung và sử dụng thanh công cụ bên trên để thay đổi kiểu hiển thị như mong muốn (thay đổi kiểu chữ đậm, in nghiêng, gạch chân; tạo đoạn văn; chèn hình ảnh;...).</p></li><li><p>Xem lại nội dung sẽ hiển thị trên website trong "Xem lại nội dung".</p></li><li><p>Sau khi đã hoàn thành chỉnh sửa nội dung hiển thị, click vào "Sao chép vào clipboard" sau đó paste vào tài liệu.</p></li></ol>',
      post_content: '<h4><em>[Ví dụ]</em> Nội dung cần thể hiện</h4><p></p><ul><li><p>Yếu tố <u>đầu tiên</u></p></li><li><p>Yếu tố <u>tiếp theo</u></p></li></ul><p>Nội dung nội dung nội dung nội dung nội dung <strong>nội dung</strong> <s>nội dung</s> <em>nội dung</em> ...</p><blockquote><p>Nội dung nội dung nội dung nội dung nội dung <strong>nội dung</strong> <s>nội dung</s> <em>nội dung</em> ...</p></blockquote><p><code>Nội dung nội dung nội dung nội dung nội dung nội dung nội dung nội dung ...</code></p>',
      fined_html:'',
    }),
    watch: {
      post_content: async function(){
        this.fined_html = '';
      },
    },
    methods: {
      async copyContent() {
        var html_content = this.post_content;
        html_content = await this.processInline(html_content);
        html_content = await this.processQuickdef(html_content);
        //console.log('html_content:', html_content);
        navigator.clipboard.writeText(html_content);
      },
      blobToData: (blob) => {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.readAsDataURL(blob)
        })
      },
      async processQuickdef(content){
        var inlines = content.match(/@LOKAQDEF.*?LOKAQDEF@/g);
        if (inlines && inlines.length > 0){
          await inlines.forEach(element => {
            var inline_text = element.replace('@LOKAQDEF','').replace('LOKAQDEF@','');
            var html_text = quickdef(inline_text);
            content = content.replace(element, html_text);
          })
        }
        return content;
      },
      async processInline(content){
        var inlines = content.match(/@INLINE.*?INLINE@/g);
        if (inlines && inlines.length > 0){
          await inlines.forEach(element => {
            var inline_text = element.replace('@INLINE','').replace('INLINE@','');
            var html_text = this.htmlDecode(inline_text);
            //console.log('html_text:', html_text);
            content = content.replace(element, html_text);
          })
        }
        return content;
      },
      htmlDecode(input){
        //console.log('htmlDecode(input):', input);
        try{
          var e = document.createElement('div');
          e.innerHTML = input;
          return e.childNodes[0].nodeValue;
        }catch(err){
          console.log(err);
          return input;
        }
      },
      async preview(content){
        var html_content = content;
        html_content = await this.processInline(html_content);
        html_content = await this.processQuickdef(html_content);
        this.fined_html = html_content;
      },
    },
  }
</script>
<style>
  #preview_div {
    width: 100%;
  }
  #preview_div img,  .tiptap-edit img{
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #preview_div blockquote{
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding-left: 1em;
    margin: 20px 0!important;
  }
  #preview_div video{
    max-width: 75%;
  }
  #preview_div iframe{
    max-width: 75%;
  }
</style>