<template>
    <div class="text-center ma-0 fixedContainer">
        <v-sheet
        v-if="suggest_list.length > 0" class="text-center pa-0 ma-0 rounded-t-lg" :color= "colorSet.backgroundTrans"
      >
        <div v-if="show_suggestion">
                <v-sheet tile :width="calFabWidth()" color="rgba(0,0,0,0.5)" class="rounded-t-lg">
                    <!--<v-list nav dense class="px-2 py-3 my-0" color="transparent">
                        <v-list-item-group>
                            <v-list-item dense v-for="(suggest, index) in suggest_list" :key="'suggest' + index"
                                class="px-2 ma-0 text-left" @click='gotoContent(suggest.destination)' color="rgba(255,0,0,0.5)">
                                <v-icon small class="mb-1" color="yellow">mdi-lightbulb-on-outline</v-icon>
                                <span class="mx-2 white--text" :class="colorSet.background">{{suggest.tag}}</span>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>-->
                <v-sheet class="pa-2" color="transparent">
                    <v-row class="pa-2 ma-2 text-left row" v-for="(suggest, index) in suggest_list" :key="index">
                        <v-col class="pa-0 ma-0" cols="9" md="9">
                            <span class="white--text question text-truncate">{{getintroText(suggest.destination)}}</span>
                        </v-col>
                        <v-col class="py-0 text-center" cols="3" md="3">
                            <v-btn small text class="pa-0 ma-0 yellow--text text-lowercase" @click="acceptSuggestion(suggest)">Xem ngay</v-btn>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="9" md="9"><span class="white--text content col-9 text-truncate">{{suggest.tag}}</span></v-col>
                        
                        <v-col class="py-0 text-center" cols="3" md="3">
                            <v-btn small text class="pa-0 ma-0 white--text text-lowercase" @click="declineSuggestion(suggest)">Để sau</v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-sheet>
            </div>
        <v-btn text class="pa-0 ma-0" @click="show_suggestion = !show_suggestion;" color= "transparent" height="24px" width="128px">
            <v-icon v-if="!show_suggestion" medium class="mb-0 neon_text" color="yellow accent-1">mdi-arrow-up-bold</v-icon>
            <v-icon v-else medium class="mb-0 neon_text" color="yellow accent-1">mdi-arrow-down-bold</v-icon>
        </v-btn>
      </v-sheet>
      </div>
</template>
<script>
    //import {nomalizeText, createGotoText} from '../utils/text_utils.js'
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        props: ['tab_name', 'nav_list', 'suggest_list', 'gotoEvent'],
        data: ()=>({
            fab: false,
            sheet: true,
            bestSuggestion: 'Tin mới: dịch vụ đưa rước cô dâu chú rể  yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy',
            //bestSuggestion: 'Tin mới: xxxxxxxxxxxxxx',
            show_suggestion: false,

        }),
        computed:{
            ...mapGetters([
                'colorSet',
            ]),
        },
        methods:{
            ...mapMutations([
                'updateSuggestion',
            ]),
            getintroText(destination){
                if (destination.includes('tab_shop'))
                    return "Bạn có muốn tìm hiểu thông tin cửa hàng";
                if (destination.includes('tab_product'))
                    return "Có phải bạn đang tìm sản phẩm";
                if (destination.includes('tab_fqa'))
                    return "Bạn có đang thắc mắc";
                if (destination.includes('tab_news'))
                    return "Bạn hãy xem tin tức từ cửa hàng nhé ";
            },
            calFabWidth(){
                var width = "300px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': width = "380px"; break;
                    case 'sm': width = "600px"; break;
                    case 'md': width = "600px"; break;
                    case 'lg': width = "600px"; break;
                    case 'xl': width = "600px"; break;
                }
                return width;
            },
            gotoContent(text){
                this.gotoEvent(text);
            },
            acceptSuggestion(suggest){
                //this.updateSuggestion({'nav':suggest.destination, 'accept':true});
                this.gotoContent(suggest.destination);
            },
            declineSuggestion(suggest){
                this.updateSuggestion({'nav':suggest.destination, 'accept':false});
            },
        },
    }
</script>
<style>
    .v-speed-dial__list {
        position: relative;
    }
    .snackbar-text {
        font-size: 14px;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: normal;
    }
    .dialog-suggestion {
        bottom: 36px !important;
        left: 0 !important; 
        right: 0 !important;
        position: absolute !important;
    }
</style>
