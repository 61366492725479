<template>
    <div class="pa-0 ma-0">
        <template v-if="!loadingData">
            <template v-if="searchResult && searchResult.length > 0">
                <v-row class="pa-0 ma-0">
                    <v-col class="my-2" cols="12" md="12" v-for="(item, index) in searchResult" :key="index">
                        <template v-if="item.type == 0">
                            <v-card class="mx-auto py-2" :max-width="responsiveWidth()">
                                <!--<v-sheet color="rgba(236, 239, 241,0.5)" @click="showNews(item)">-->
                                <!--<v-sheet @click="gotoItem(item)">-->
                                <v-row class="pt-4 pb-0 px-4 ma-0 pt-md-8 px-md-8" @click="gotoItem(item)">
                                    <v-col cols="10" md="10" class="text-left pa-0 ma-0">
                                        <v-card-title class="pa-0">
                                            <span class="text-subtitle-1 font-weight-bold">{{getTitle(item.orgName)}}</span>
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="2" md="2" class="pa-0 ma-0 text-right">
                                        <!--<v-chip small class="px-2 text-caption" outlined color="grey darken-1">tin tức</v-chip>
                                        -->
                                    </v-col>
                                    <v-col cols="12" md="12" class="text-left pa-0 ma-0">
                                        <v-card-text class="text-left pa-0">
                                            <span v-if="item.orgAddress" class="text-caption">{{item.orgAddress}}</span>
                                            <span v-else class="text-caption">{{item.orgId}}</span>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                <!--</v-sheet>-->
                                <v-row align="start" justify="start" class="pt-2 pb-4 px-4 ma-0 pt-md-4 px-md-8">
                                    <template v-if="item.snippet.length > 0">
                                        <v-col cols="8" md="8" class="pa-0 ma-0">
                                            <v-row class="pa-0 ma-0">
                                                <v-col cols="12" md="12" class="pa-0" v-for="(snippet_item, s_idx) in item.snippet" :key="s_idx" >
                                                    <v-card-text class="search-snippet text-left py-0 px-0" :class="{'text-caption':$vuetify.breakpoint.smAndDown}">
                                                        <v-sheet v-html="snippet_item[1]" ></v-sheet>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </template>
                                    <template v-else>
                                        <v-col cols="8" md="8" class="pa-0 ma-0">
                                            <v-row class="pa-0 ma-0">
                                                <v-col cols="12" md="12" class="pa-0" v-for="(detail_item, s_idx) in item.orgDetail" :key="s_idx">
                                                    <v-card-text class="text-left text-truncate py-0 px-0" :class="{'text-caption':$vuetify.breakpoint.smAndDown}" max-width="100%">
                                                        <span>{{detail_item}}</span>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </template>
                                    <v-col cols="4" md="4" class="pa-0 pb-2">
                                        <div class="pb-0 px-2">
                                            <v-img :src="item.orgAvatar" :height="calImageHeight()" ></v-img>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                        <template v-if="item.type == 1">
                            <v-card class="mx-auto py-0" :max-width="responsiveWidth()">
                                <!--<v-sheet color="rgba(236, 239, 241,0.5)" @click="showNews(item)">-->
                                <!--<v-sheet @click="gotoItem(item)">-->
                                <v-row class="pt-4 pb-0 px-4 ma-0 pt-md-8 px-md-8" @click="gotoItem(item)">
                                    <v-col cols="10" md="10" class="text-left pa-0 ma-0">
                                        <v-card-title class="pa-0">
                                            <span class="text-subtitle-1 font-weight-bold">{{getTitle(item.itemName)}}</span>
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="2" md="2" class="pa-0 ma-0 text-right">
                                        <v-chip small class="px-2 text-caption" outlined color="grey darken-1">item</v-chip>
                                    </v-col>
                                    <v-col cols="12" md="12" class="text-left pa-0 ma-0">
                                        <v-card-text class="text-left pa-0">
                                            <span class="text-caption">{{item.orgId}}</span>
                                            -
                                            <span class="text-caption">{{item.itemId}}</span>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                <!--</v-sheet>-->
                                <v-row align="start" class="pt-2 pb-4 px-4 ma-0 pt-md-4 px-md-8">
                                    <v-col cols="8" md="8" class="pa-0 ma-0">
                                        <v-row class="pa-0 ma-0">
                                            <v-col cols="12" md="12" class="pa-0 ma-0" v-for="(snippet_item, s_idx) in item.snippet" :key="s_idx">
                                                <v-card-text class="search-snippet text-left pa-0" :class="{'text-caption':$vuetify.breakpoint.smAndDown}">
                                                    <v-sheet v-html="snippet_item[1]"></v-sheet>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4" md="4" class="pa-0 ma-0 text-right">
                                        <div class="pb-0 px-2">
                                            <v-img :src="item.itemAvatar" :max-height="calImageHeight()"></v-img>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                        <template v-if="item.type == 2">
                            <v-card class="mx-auto py-0" :max-width="responsiveWidth()">
                                <!--<v-sheet color="rgba(236, 239, 241,0.5)" @click="showNews(item)">-->
                                <v-sheet @click="gotoItem(item)">
                                    <v-card-title class="pt-3 pb-0">
                                        <div class="text-left text-subtitle-1 font-weight-bold" v-html="getTitle(item.newsTitle)"></div>
                                    </v-card-title>
                                    <v-card-text class="text-left pb-1">
                                        <span class="text-caption">{{item.newsDate}}</span>
                                    </v-card-text>
                                </v-sheet>
                                <v-row align="start" justify="start" class="py-4">
                                    <v-col cols="12" md="12" class="py-0" v-for="(snippet_item, s_idx) in item.snippet" :key="s_idx">
                                        <v-card-text class="search-snippet text-left py-1">
                                            <v-sheet v-html="snippet_item[1]" width="100%"></v-sheet>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </template>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <span class="pa-4 orange--text">Không tìm thấy dữ liệu nào.</span>
            </template>
        </template>
        <template v-else>
            <span class="pa-4">Đang tải dữ liệu, vui lòng chờ trong giây lát.</span>
        </template>
    <v-dialog v-model="show_anews" :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="1200px">
    <template v-if="selected_news">
        <v-toolbar dark dense>
            <v-btn icon @click="show_anews = false;">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title >{{selected_news.newsTitle}}</v-toolbar-title>
        </v-toolbar>
        <v-card class="text-left pb-2" tile>
            <v-card-subtitle class="pt-2">
                <v-sheet v-html="selected_news.newsContent" class="news_detail quickview_layout">
                </v-sheet>
            </v-card-subtitle>
            <v-card-actions>
                <v-row>
                    <v-col cols="6" md="6" class="text-left">
                        <v-btn class="text-capitalize font-italic text-caption" text @click="getUrlToNews(selected_news.id)">
                            Sao chép url
                        </v-btn>
                    </v-col>
                    <v-col cols="6" md="6" class="text-right">
                        <v-btn @click="show_anews = false;" outlined>
                            Đóng
                        </v-btn>
                    </v-col>
                </v-row>
                <v-snackbar v-model="snackbar" :timeout="3000" light class="text-center">
                    {{actionResult}}
                </v-snackbar>
            </v-card-actions>
        </v-card>
    </template>
</v-dialog>
    </div>
</template>
<script>
    //import {roomsRef, firebaseAuth, dbTimestamp} from '@/firestore';
    //import {firebaseAuth} from '@/firestore';
    import DataService from "../services/DataService.js";
    //import ProductDetail from "../components/ProductDetail.vue";
    import { mapGetters, mapMutations } from 'vuex';
    import { createGotoText, parseGotoText, fineTitle} from "../utils/text_utils.js";
    import {urlCommons} from "../utils/commons.js";
    import {getDate} from "../utils/dates.js";

    export default {
        props: ['query', 'navigate','gotoX', 'leaveX'],
        components:{
            
        },
        data: ()=>({
            show_list:[],
            max_height:[],
            selected_news:null,
            show_anews:false,
            snackbar:false,
            actionResult:"",
            searchResult:null,
            loadingData: true,
        }),
        mounted(){
            setTimeout(() => {
                if (this.gotoX) {
                    var tmp = parseGotoText(this.gotoX);
                    if (tmp && tmp.controlId) {
                        this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                        this.$vuetify.goTo('#id-' + tmp.controlId, {offset:100});
                    }
                }
            }, 300);
            this.$watch('gotoX', this.gotoXWatcher);
            this.$watch('leaveX', this.leaveXWatcher);
            this.$watch('query', this.changeSearch);

            this.searchKeyword(this.query[0], this.query[1].lat, this.query[1].lng, 20000, 0);
        },
        computed: {
            ...mapGetters([
                'storedHistory',
                'colorSet',
            ]),
        },
        methods:{
            ...mapMutations([
                'addStoredHistory',
            ]),
            gotoXWatcher(newValue){
                var tmp = parseGotoText(newValue);
                if (tmp && tmp.controlId){
                    this.$vuetify.goTo('#id-'+ tmp.controlId, {offset:100});
                    //console.log('[gotoXWatcher], goto:', tmp.controlId);
                }
            },
            leaveXWatcher(){
                //close dialog when leave my tab
                this.show_anews = false;
                this.selected_news = null;
            },

            changeSearch(newValue){
                //console.log('changeSearch:', newValue);
                this.loadingData = true;
                this.localNews = null;
                if (newValue[0] && newValue[0].length > 0){
                    this.searchKeyword(newValue[0] + " ", newValue[1].lat, newValue[1].lng, 20000, 0);
                }else{
                    this.searchKeyword(newValue[0], newValue[1].lat, newValue[1].lng, 20000, 0);
                }
            },

            async searchKeyword(keyword, lat, lng, radius, page) {
                if (keyword == '' || keyword.length == 0) {
                    //console.log('searchKeyword >> searchNearOrg, keyword:', keyword);
                    const nearShops = await DataService.searchNearOrg(lat, lng, radius, page);
                    //console.log('nearShops:', nearShops);
                    var matchedOrg = [];
                    await Promise.all(nearShops.data.result.hits.map(el => {
                        matchedOrg.push({ type: 0, orgId: el.dbId, orgName: el.displayName, orgAvatar: el.image, orgAddress: el.address, snippet: [], orgDetail: el.businessType});
                    }));
                    this.searchResult = matchedOrg;
                    this.loadingData = false;
                } else {
                    //console.log('searchKeyword >> searchByKeyword, keyword:', keyword, keyword.charCodeAt(0));
                    const find_result = await DataService.searchByKeyword(keyword, lat, lng, radius, page);
                    //console.log("find_result:", find_result);
                    if (find_result && find_result.data.result && find_result.data.result.length == 2) {
                        const orgResult = await this.getOrgResult(find_result.data.result[0]);
                        const itemResult = await this.getItemResult(find_result.data.result[1]);
                        //const newsResult = await this.getNewsResult(find_result.data.result[2]);
                        this.loadingData = false;
                        //console.log("orgResult:", orgResult);
                        //console.log("itemResult:", itemResult);
                        var tmp_ = itemResult.concat(orgResult);
                        //tmp_ = tmp_.concat(orgResult);
                        //console.log("tmp_:", tmp_);
                        this.searchResult = tmp_;
                    } else {
                        this.loadingData = false;
                    }
                }
            },

            async getOrgResult(results){
                var matchedOrg = [];
                await Promise.all(results.hits.map(async(el) => {
                    var snippet_ = await Object.keys(el._snippetResult).reduce(async (result, key) => {
                        const result_tmp = await result;
                        if (Array.isArray(el._snippetResult[key])){
                            await Promise.all(el._snippetResult[key].map(snippet_item =>{
                                result_tmp.push([key, snippet_item["value"]]);
                            }));
                        } else {
                            if (el._snippetResult[key]["matchLevel"] && el._snippetResult[key]["matchLevel"] != "none") {
                                result_tmp.push([key, el._snippetResult[key]["value"]]);
                            }
                        }
                        return result_tmp;
                    },[])
                    matchedOrg.push({type:0, orgId: el.dbId, orgName: el.displayName, orgAvatar: el.image, snippet: snippet_});
                }));
                return matchedOrg;
            },

            async getItemResult(results){
                var matchedItem = [];
                await Promise.all(results.hits.map(async(el) => {
                    var snippet_ = await Object.keys(el._snippetResult).reduce(async (result, key) => {
                        const result_tmp = await result;
                        if (Array.isArray(el._snippetResult[key])) {
                            await Promise.all(el._snippetResult[key].map(snippet_item => {
                                result_tmp.push([key, snippet_item["value"]]);
                            }));
                        } else {
                            if (el._snippetResult[key]["matchLevel"] && el._snippetResult[key]["matchLevel"] != "none") {
                                result.push([key, el._snippetResult[key]["value"]]);
                            }
                        }
                        return result_tmp;
                    },[])
                    matchedItem.push({type:1, orgId: el.org_, itemId: el.dbId, itemName: el.name, itemAvatar: el.image, snippet: snippet_});
                }));
                return matchedItem;
            },

            async getNewsResult(results){
                console.log(results);
                var matchedNews = [];
                await Promise.all(results.hits.map(async(el) => {
                    var snippet_ = Object.keys(el._snippetResult).reduce(function (result, key) {
                        if (el._snippetResult[key]["matchLevel"] && el._snippetResult[key]["matchLevel"] != "none"){
                            result.push([key, el._snippetResult[key]["value"]]);
                        }
                        return result;
                    },[])
                    matchedNews.push({type:2, orgId: el.org_, newsId: el.dbId, newsTitle: el.title, newsDate: getDate({_seconds: el.createdDate}),newsAvatar: el.avatar_link, snippet: snippet_});
                }));
                return matchedNews;
            },

            gotoShop(shopId){
                return this.$router.push({
                            name: "Shop",
                            params: { id: shopId}
                        });
            },
            isMobile(){
                var isMobile = true;
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': isMobile = true; break;
                    case 'sm': isMobile = true; break;
                    case 'md': isMobile = false; break;
                    case 'lg': isMobile = false; break;
                    case 'xl': isMobile = false; break;
                }
                return isMobile;
            },
            responsiveWidth(){
                var width = "100%";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': width = "100%"; break;
                    case 'sm': width = "80%"; break;
                    case 'md': width = "70%"; break;
                    case 'lg': width = "70%"; break;
                    case 'xl': width = "70%"; break;
                }
                return width;
            },
            calImageHeight(){
                var height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': height = "100px"; break;
                    case 'sm': height = "120px"; break;
                    case 'md': height = "150px"; break;
                    case 'lg': height = "150px"; break;
                    case 'xl': height = "150px"; break;
                }
                return height;
            },
            gotoItem(content){
                let routeData = null;
                switch (content.type){
                    case 0: routeData = this.$router.resolve({name: 'Shop', params: {id: content.orgId}}); break;
                    case 1: routeData = this.$router.resolve({name: 'QuickView', params: {shopId: content.orgId, itemType: "prod", itemId: content.itemId}}); break;
                    case 2: routeData = this.$router.resolve({name: 'QuickView', params: {shopId: content.orgId, itemType: "news", itemId: content.newsId}}); break;
                }
                console.log('routeData.href:', routeData.href);
                window.open(routeData.href, '_blank');
            },
            getUrlToNews(newsId){
                var url_ = urlCommons['domain'] + '/pr/' + urlCommons['url_shop'] + '/' + this.shopId + '/' + urlCommons['url_quickview']
                        + '/' + urlCommons['url_qkvw_news'] + '/' + newsId;
                navigator.clipboard.writeText(url_);
                this.snackbar = true;
                //TODO resolve overflow problem
                //this.actionResult= "Đã sao chép url\n"+ url_;
                this.actionResult = "Đã sao chép vào clipboard";
            },
            getAvatar(content){
                if (content.avatar){
                    return content.avatar;
                }else if (content.avatar_link){
                    return content.avatar_link;
                }
            },
            getTitle(title){
                return fineTitle(this.$vuetify.breakpoint.name, title, [35, 40, 80, 80, 80]);
            },
            getDetail(content){
                return fineTitle(this.$vuetify.breakpoint.name, content, [30, 30, 80, 80, 80]);
            }
        }
    }
</script>
<style>
    .news_detail{
        word-wrap: break-word;
        white-space: normal ;
        word-break: normal;
        overflow: hidden ;
        text-overflow: ellipsis;
    }
    .news_detail img{
        max-width: 100%;
    }
    .news_detail video{
        max-width: 100%;
    }
    .news_detail iframe{
        max-width: 100%;
    }
    .search-snippet em{
        font-weight: bold !important;
        font-style: italic !important;
        line-break: strict;
    }
</style>
