<template>
    <div class="pa-0 ma-0">
        <template v-if="!loadingData">
            <template v-if="localNews">
                <v-row class="pa-0 ma-0">
                    <v-col class="my-2" cols="12" md="12" v-for="(item, index) in localNews" :key="index">
                        <v-card class="mx-auto py-0" :max-width="calCardWidth()">
                            <!--<v-sheet color="rgba(236, 239, 241,0.5)" @click="showNews(item)">-->
                            <!--<v-sheet @click="gotoNews(item)">-->
                            <v-row class="pt-4 pb-0 px-4 ma-0 pt-md-8 px-md-8" @click="gotoNews(item)">
                                <v-col cols="10" md="10" class="text-left pa-0 ma-0">
                                    <v-card-title class="pa-0">
                                        <div class="text-left text-subtitle-1 font-weight-bold" v-html="getTitle(item.newsTitle)"></div>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="2" md="2" class="pa-0 ma-0 text-right">
                                    <!--<v-icon color="orange" class="pr-2">mdi-newspaper-variant-outline</v-icon>-->
                                    <v-chip small class="px-2 text-caption" outlined color="grey darken-1">tin tức</v-chip>
                                </v-col>
                                <v-col cols="12" md="12" class="text-left pa-0 ma-0">
                                    <v-card-text class="text-left pb-1 pt-0 px-0">
                                        <span class="text-caption">{{item.newsDate}}</span>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                            <v-row align="start" justify="start" class="pt-2 pb-4 px-4 ma-0 pt-md-4 px-md-8">
                                <v-col cols="7" md="7" class="pa-0">
                                    <template v-if="isMobile()">
                                        <v-card-text class="font-weight-medium text-left pa-0">
                                            <span class="text-left">{{item.newsDescription}}</span>
                                        </v-card-text>
                                    </template>
                                    <template v-else>
                                        <div class="news-content-description pa-0 text-left" v-html="item.newsContent"></div>
                                    </template>
                                </v-col>
                                <v-col cols="5" md="5" justify="center" class="pa-0">
                                    <div class="pa-0">
                                        <v-img :src="item.newsAvatar" contain :max-height="calImageHeight()"></v-img>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-sheet color="rgba(236, 239, 241,0.5)">
                                <v-row class="mx-0 text-left ">
                                    <v-list-item @click="gotoShop(item.orgId)">
                                        <v-list-item-avatar color="grey darken-3" size="20">
                                            <!--<v-img class="elevation-6" alt="" :src="item.orgAvatar">
                                            </v-img>-->
                                            <v-icon small color="grey lighten-3">mdi-home</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-body-2">{{item.orgName}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-row>
                            </v-sheet>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <span class="pa-4">Không tìm thấy dữ liệu nào, bạn hãy chọn khu vực khác.</span>
            </template>
        </template>
        <template v-else class="pa-4">
            <span >Đang tải dữ liệu, vui lòng chờ trong giây lát.</span>
        </template>
    <v-dialog v-model="show_anews" :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="1200px">
    <template v-if="selected_news">
        <v-toolbar dark dense>
            <v-btn icon @click="show_anews = false;">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title >{{selected_news.newsTitle}}</v-toolbar-title>
        </v-toolbar>
        <v-card class="text-left pb-2" tile>
            <v-card-subtitle class="pt-2">
                <v-sheet v-html="selected_news.newsContent" class="news_detail quickview_layout">
                </v-sheet>
            </v-card-subtitle>
            <v-card-actions>
                <v-row>
                    <v-col cols="6" md="6" class="text-left">
                        <v-btn class="text-capitalize font-italic text-caption" text @click="getUrlToNews(selected_news.id)">
                            Sao chép url
                        </v-btn>
                    </v-col>
                    <v-col cols="6" md="6" class="text-right">
                        <v-btn @click="show_anews = false;" outlined>
                            Đóng
                        </v-btn>
                    </v-col>
                </v-row>
                <v-snackbar v-model="snackbar" :timeout="3000" light class="text-center">
                    {{actionResult}}
                </v-snackbar>
            </v-card-actions>
        </v-card>
    </template>
</v-dialog>
    </div>
</template>
<script>
    //import {roomsRef, firebaseAuth, dbTimestamp} from '@/firestore';
    //import {firebaseAuth} from '@/firestore';
    import DataService from "../services/DataService.js";
    //import ProductDetail from "../components/ProductDetail.vue";
    import { mapGetters, mapMutations } from 'vuex';
    import { createGotoText, parseGotoText, fineTitle} from "../utils/text_utils.js";
    import {urlCommons} from "../utils/commons.js";
    import {getDate} from "../utils/dates.js";

    export default {
        props: ['geo_loc', 'navigate','gotoX', 'leaveX'],
        components:{
            //'news-list': ProductDetail,
        },
        data: ()=>({
            show_list:[],
            max_height:[],
            selected_news:null,
            show_anews:false,
            snackbar:false,
            actionResult:"",
            localNews:null,
            loadingData: true,
            is_mobile: false,
        }),
        mounted(){
            setTimeout(() => {
                if (this.gotoX) {
                    var tmp = parseGotoText(this.gotoX);
                    if (tmp && tmp.controlId) {
                        this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                        this.$vuetify.goTo('#id-' + tmp.controlId, {offset:100});
                    }
                }
            }, 300);
            this.$watch('gotoX', this.gotoXWatcher);
            this.$watch('leaveX', this.leaveXWatcher);
            this.$watch('geo_loc', this.changeLocation);

            this.getLocalNews(this.geo_loc.lat, this.geo_loc.lng, 30000, 15, 0);

            switch (this.$vuetify.breakpoint.name) {
                case 'xs': this.is_mobile = true; break;
                case 'sm': this.is_mobile = true; break;
                case 'md': this.is_mobile = false; break;
                case 'lg': this.is_mobile = false; break;
                case 'xl': this.is_mobile = false; break;
            }
        },
        computed: {
            ...mapGetters([
                'storedHistory',
                'colorSet',
                'shopId',
            ]),
        },
        methods:{
            ...mapMutations([
                'addStoredHistory',
            ]),
            gotoXWatcher(newValue){
                var tmp = parseGotoText(newValue);
                if (tmp && tmp.controlId){
                    //this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                    this.$vuetify.goTo('#id-'+ tmp.controlId, {offset:100});
                    //console.log('[gotoXWatcher], goto:', tmp.controlId);
                }
            },
            leaveXWatcher(){
                //close dialog when leave my tab
                this.show_anews = false;
                this.selected_news = null;
            },
            changeLocation(newValue){
                //console.log('changeLocation:', newValue);
                this.loadingData = true;
                this.localNews = null;
                this.getLocalNews(newValue.lat, newValue.lng, 30000, 15, 0);
            },

            async getLocalNewsDB(lat, lng, radius){
                const find_result = await DataService.getNearOrg(lat, lng, radius);
                //console.log('find_result:', find_result);
                if (find_result && find_result.data && find_result.data.result.length > 0){
                    var localNews = [];
                    await Promise.all(find_result.data.result.map(async (element) => {
                        const news_ = await DataService.getNewsList(element.id, 3, 15);
                        if (news_ && news_.data && news_.data.result.length > 0){
                            await Promise.all(news_.data.result.map(element_news => {
                                localNews.push({shopId: element.id, shopName: element.display_name, shopAvatar:element.intro_image1, 
                                    newsTitle:element_news.title, newsDescription:element_news.description, newsContent:element_news.content, 
                                    newsDate: getDate(element_news.created_date), newsAvatar:element_news.avatar_link});
                            }));
                        }
                    }));
                    //console.log(localNews);
                    this.localNews = localNews;
                    this.loadingData = false;
                }else{
                    this.loadingData = false;
                }
            },

            async getLocalNews(lat, lng, radius, daybefore, page){
                const find_result = await DataService.searchNews(lat, lng, radius, daybefore, page);
                if (find_result && find_result.data.result && find_result.data.result.hits.length > 0){
                    //console.log('find_result:', find_result.data.result);
                    var localNews = [];
                    await Promise.all(find_result.data.result.hits.map(element => {
                        //console.log('element:', element.org_);
                        localNews.push({
                            orgId: element.org_, orgName: element.org_, orgAvatar: '',
                            newsId: element.dbId,
                            newsTitle: element.title, newsDescription: element.description, newsContent: element.content,
                            newsDate: getDate({_seconds:element.createdDate}), newsAvatar: element.avatar_link
                        });
                    }));
                    this.localNews = localNews;
                    this.loadingData = false;
                }else{
                    this.loadingData = false;
                }
            },

            gotoShop(shopId){
                return this.$router.push({
                            name: "Shop",
                            params: { id: shopId}
                        });
            },
            isMobile(){
                var isMobile = true;
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': isMobile = true; break;
                    case 'sm': isMobile = true; break;
                    case 'md': isMobile = false; break;
                    case 'lg': isMobile = false; break;
                    case 'xl': isMobile = false; break;
                }
                return isMobile;
            },
            calImageHeight(){
                var height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': height = "100px"; break;
                    case 'sm': height = "100px"; break;
                    case 'md': height = "150px"; break;
                    case 'lg': height = "150px"; break;
                    case 'xl': height = "150px"; break;
                }
                return height;
            },
            calCardWidth(){
                var width = "100%";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': width = "100%"; break;
                    case 'sm': width = "100%"; break;
                    case 'md': width = "80%"; break;
                    case 'lg': width = "80%"; break;
                    case 'xl': width = "80%"; break;
                }
                return width;
            },
            showNews(anews){
                //Add history
                //this.addStoredHistory(createGotoText("tab_news", anews.id));

                this.show_anews = true;
                this.selected_news = anews;
            },
            gotoNews(content){
                var routeData = this.$router.resolve({name: 'QuickView', params: {shopId: content.orgId, itemType: "news", itemId: content.newsId}});
                console.log('routeData.href:', routeData.href);
                window.open(routeData.href, '_blank');
            },
            getUrlToNews(newsId){
                var url_ = urlCommons['domain'] + '/pr/' + urlCommons['url_shop'] + '/' + this.shopId + '/' + urlCommons['url_quickview']
                        + '/' + urlCommons['url_qkvw_news'] + '/' + newsId;
                navigator.clipboard.writeText(url_);
                this.snackbar = true;
                //TODO resolve overflow problem
                //this.actionResult= "Đã sao chép url\n"+ url_;
                this.actionResult = "Đã sao chép vào clipboard";
            },
            getAvatar(content){
                if (content.avatar){
                    return content.avatar;
                }else if (content.avatar_link){
                    return content.avatar_link;
                }
            },
            getTitle(title){
                return fineTitle(this.$vuetify.breakpoint.name, title, [35, 40, 80, 80, 80]);
            }
        }
    }
</script>
<style>
    .news_detail{
        word-wrap: break-word;
        white-space: normal ;
        word-break: normal;
        overflow: hidden ;
        text-overflow: ellipsis;
    }
    .news_detail img{
        max-width: 100%;
    }
    .news_detail video{
        max-width: 100%;
    }
    .news_detail iframe{
        max-width: 100%;
    }
    .news-content-description{
        max-height: 150px;
        margin-bottom: 16px;
        overflow: hidden;
        font-size: 14px;
    }
</style>
