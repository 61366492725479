<template>
    <div class="pa-0 ma-0">
    <v-row class="pa-0 ma-0">
        <v-col cols="12" md="4" v-for="(content, index) in news" :key="index">
        <v-card class="mx-auto" max-width="1200" :id="'id-' + content.id">
            <v-img eager class="white--text align-end" :src="getAvatar(content)" lazy-src="/image-filter-black-white_128.png" height="200px" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" @click="showNews(content)">
                <v-card-title >
                    <div v-html="getTitle(content.title)"></div>
                </v-card-title>
            </v-img>      
            <v-card-subtitle class="pb-0">
                <template v-if="content.description">
                    <v-sheet v-html="content.description" :height="calDescriptionHeight()" class="news_detail">
                    </v-sheet>
                </template>
                <template v-else>
                    <v-sheet v-html="content.content" :max-height="max_height[index]" class="news_detail">
                    </v-sheet>
                </template>
            </v-card-subtitle>
            <v-card-actions>
                <!--<v-btn color="orange lighten-2" text @click="toggleShow(index)">
                    {{show_list[index] ? 'Thu gọn' : 'Xem thêm'}}
                </v-btn>      
                <v-spacer></v-spacer>
                <v-btn icon @click="toggleShow(index)">
                    <v-icon>{{ show_list[index] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>-->
                <v-btn :color="colorSet.buttonColor" text @click="showNews(content)">
                    Xem thêm
                </v-btn> 
            </v-card-actions>
        </v-card>
    </v-col>
    </v-row>
    <v-dialog v-model="show_anews" :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="1200px">
        <template v-if="selected_news">
            <v-toolbar dark dense :color="colorSet.background">
                <v-btn icon @click="selected_news = null; show_anews = false;" :class="colorSet.textColor">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title :class="colorSet.textColor">{{selected_news.title}}</v-toolbar-title>
            </v-toolbar>
            <v-card class="text-left pb-2" tile>
                <!--<v-card-subtitle class="pt-2">
                    <v-sheet v-html="selected_news.content" class="news_detail quickview_layout">
                    </v-sheet>
                </v-card-subtitle>-->
                <newsdetail :news="selected_news"></newsdetail>
                <v-card-actions>
                    <v-row>
                        <v-col cols="6" md="6" class="text-left">
                            <v-btn class="text-capitalize font-italic text-caption" :color="colorSet.buttonColor" text
                                @click="getUrlToNews(selected_news.id)">
                                Sao chép url
                            </v-btn>
                        </v-col>
                        <v-col cols="6" md="6" class="text-right">
                            <v-btn :color="colorSet.buttonColor" @click="selected_news = null; show_anews = false; " outlined>
                                Đóng
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-snackbar v-model="snackbar" :timeout="3000" light class="text-center">
                        {{actionResult}}
                    </v-snackbar>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
    </div>
</template>
<script>
    //import {roomsRef, firebaseAuth, dbTimestamp} from '@/firestore';
    //import {firebaseAuth} from '@/firestore';
    //import DataService from "../services/DataService.js";
    import NewsDetail from "../components/NewsDetail.vue";
    import { mapGetters, mapMutations } from 'vuex';
    import { nomalizeText, createGotoText, parseGotoText, fineTitle} from "../utils/text_utils.js";
    import {urlCommons} from "../utils/commons.js";
    //import {addSuggestions} from "../utils/suggestion_utils.js";

    export default {
        props: ['news', 'shopInfo','common', 'navigate','gotoX', 'leaveX'],
        components:{
            'newsdetail': NewsDetail,
        },
        data: ()=>({
            show_list:[],
            max_height:[],
            selected_news:null,
            show_anews:false,
            snackbar:false,
            actionResult:"",
        }),
        created(){
            //this.panels = [...Array(Object.values(this.products).length).keys()];
            //console.log('this.panels', this.panels);
        },
        mounted(){
            setTimeout(() => {
                if (this.gotoX) {
                    var tmp = parseGotoText(this.gotoX);
                    if (tmp && tmp.controlId) {
                        this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                        this.$vuetify.goTo('#id-' + tmp.controlId, {offset:100});
                    }
                }
            }, 300);
            this.$watch('gotoX', this.gotoXWatcher);
            this.$watch('leaveX', this.leaveXWatcher);

            if(this.news){
                this.show_list = Array(this.news.length).fill(false);
                this.max_height = Array(this.news.length).fill("4.2em");
            }
        },
        computed: {
            ...mapGetters([
                'storedHistory',
                'colorSet',
                'shopId',
            ]),
        },
        methods:{
            ...mapMutations([
                'addStoredHistory',
            ]),
            gotoXWatcher(newValue){
                var tmp = parseGotoText(newValue);
                if (tmp && tmp.controlId){
                    //this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                    this.$vuetify.goTo('#id-'+ tmp.controlId, {offset:100});
                    //console.log('[gotoXWatcher], goto:', tmp.controlId);
                }
            },
            leaveXWatcher(){
                //close dialog when leave my tab
                this.show_anews = false;
                this.selected_news = null;
            },
            nomalizeText(text){
                return nomalizeText(text);
            },
            toggleShow(index){
                if (this.show_list[index]){
                    this.$set(this.show_list, index, false);
                    this.$set(this.max_height, index, "4.2em");
                }else{
                    this.$set(this.show_list, index, true);
                    this.$set(this.max_height, index, "");
                    this.addStoredHistory(createGotoText("tab_news", this.news[index].id));
                }
                
            },
            isMobile(){
                var isMobile = true;
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': isMobile = true; break;
                    case 'sm': isMobile = true; break;
                    case 'md': isMobile = false; break;
                    case 'lg': isMobile = false; break;
                    case 'xl': isMobile = false; break;
                }
                return isMobile;
            },
            calImageHeight(){
                var height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': height = "200px"; break;
                    case 'sm': height = "200px"; break;
                    case 'md': height = "250px"; break;
                    case 'lg': height = "250px"; break;
                    case 'xl': height = "250px"; break;
                }
                return height;
            },
            calDescriptionHeight(){
                var height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': height = "60px"; break;
                    case 'sm': height = "60px"; break;
                    case 'md': height = "60px"; break;
                    case 'lg': height = "60px"; break;
                    case 'xl': height = "60px"; break;
                }
                return height;
            },
            showNews(anews){
                //Add history
                this.addStoredHistory(createGotoText("tab_news", anews.id));

                this.show_anews = true;
                this.selected_news = anews;
            },
            getUrlToNews(newsId){
                var url_ = urlCommons['domain'] + '/pr/' + urlCommons['url_shop'] + '/' + this.shopId + '/' + urlCommons['url_quickview']
                        + '/' + urlCommons['url_qkvw_news'] + '/' + newsId;
                navigator.clipboard.writeText(url_);
                this.snackbar = true;
                //TODO resolve overflow problem
                //this.actionResult= "Đã sao chép url\n"+ url_;
                this.actionResult = "Đã sao chép vào clipboard";
            },
            getAvatar(content){
                if (content.avatar){
                    return content.avatar;
                }else if (content.avatar_link){
                    //console.log("avatar_link:", content.avatar_link)
                    return content.avatar_link;
                }
            },
            getTitle(title){
                return fineTitle(this.$vuetify.breakpoint.name, title, [25, 30, 30, 30, 30]);
            }
        }
    }
</script>
<style>
    .news_detail{
        word-wrap: break-word;
        white-space: normal ;
        word-break: normal;
        overflow: hidden ;
        text-overflow: ellipsis;
    }
    .news_detail img{
        max-width: 100%;
    }
    .news_detail video{
        max-width: 100%;
    }
    .news_detail iframe{
        max-width: 100%;
    }
</style>
