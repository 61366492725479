<template>
        <v-card v-if="product" :loading="false" class="ma-0 my-0 pa-2" flat tile>
            <div>
                <!--<v-card-title class="pb-0" v-text="product.product_name"></v-card-title>-->
                <v-row class="ma-0 pa-0">
                    <v-col md="6" cols="12">
                        <v-carousel :height="calImageHeight()" cycle :show-arrows="false">
                            <v-carousel-item v-for="(image, index) in product.images" :key="index"
                                :src="image" reverse-transition="scale-transition" 
                                transition="slide-y-reverse-transition"></v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <v-col cols="12" md="6" class="text-left">
                        <template v-if="product.overview">
                            <div class="text-left px-2 pb-1 qw-title" v-html="getDisplayName(product.overview)">
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-left px-2 pb-1 qw-title" v-html="getDisplayName(product.product_name)"></div>
                        </template>
                        <template v-if="product.price_before">
                        <div class="pb-2">
                            <span class="grey--text text-decoration-line-through pl-2">Giá cũ:</span>
                            <span class="grey--text text-decoration-line-through pl-1">{{product.price_before}}</span>
                            <span class="pl-2">Giá mới:</span>
                            <span class="orange--text text--darken4 pl-1">{{product.price}}</span>
                            <span class="pl-2">({{product.sale_off}})</span>
                        </div>
                        </template>
                        <template v-else>
                            <div class="pb-2" v-if="product.price">
                                <span class="pl-2">Giá bán:</span>
                                <span class="orange--text text--darken4 pl-1">{{product.price}}</span>
                            </div>
                        </template>
                        <div class="text-left mx-2 content_div pb-2" v-html="product.description"></div>
                        <div class="mx-2">
                            <template v-if="product.manufacturer">
                                <span>Nhà sản xuất:</span>
                                <span class="pl-1 pr-4">{{product.manufacturer}}</span>
                                <br>
                            </template>
                            <template v-if="product.origin">
                                <span>Xuất xứ:</span>
                                <span class="pl-1">{{product.origin}}</span>
                                <br>
                            </template>
                        </div>
                        <div v-if="product.warranty" class="mx-2 my-2">
                            <span class="font-italic">Bảo hành</span>
                            <span class="pl-1 font-italic">{{product.warranty}}</span>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div v-if="product.details">
                <div class="pb-0 px-3 text-left qw-title text-decoration-underline">Thông tin chi tiết</div>
                <v-card-text class="text-left mt-2 px-4">
                    <div class="prod_detail" v-html="product.details"></div>
                </v-card-text>
            </div>
        </v-card>
</template>
<script>
    //import {roomsRef, firebaseAuth, dbTimestamp} from '@/firestore';
    //import {firebaseAuth} from '@/firestore';
    import {fineTitle} from "../utils/text_utils.js";
    
    export default {
        props: ['product'],
        data: () => ({
            //currentShowImageIndex: 0,
        }),
        methods:{
            calImageHeight(){
                var height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': height = "350px"; break;
                    case 'sm': height = "400px"; break;
                    case 'md': height = "450px"; break;
                    case 'lg': height = "450px"; break;
                    case 'xl': height = "450px"; break;
                }
                return height;
          },
          getDisplayName(text) {
              
                return fineTitle(this.$vuetify.breakpoint.name, text, [60, 60, 80, 80, 80]);
            },
        },
    }
</script>
<style>
    .content_div {
        font-size: 14px;
    }
    .content_div td{
        padding-left: 8px;
        padding-right: 8px;
        margin-left: 8px;
    }
</style>