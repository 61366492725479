<template>
    <div>
        <v-row class="py-4 px-2 ma-0">
            <v-col cols="12" md="6" class="py-0">
                <v-card class="text-left pa-0 ma-0" flat tile>
                    <div id="ytplayer"></div>
                    <div id="tsviewer" class="pt-2"></div>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pa-0 ma-0 text-left">
                <span class="px-4">Phụ đề</span>
                <div class="transcript-content text-left ma-0">
                    <v-list class="text-left">
                        <v-list-item v-for="(item, index) in transcriptArr" :key="index">
                            <!--<v-list-item-content class="py-0">-->
                                <v-row class="pa-0 ma-0">
                                    <!--<div class="pr-2">
                                        <span>
                                            {{getTimeLine(item["start"])}}
                                        </span>
                                    </div>-->
                                    <div v-html="getTimeLine(item['start']) + ' ' + item['caption']"></div>
                                </v-row>                                
                            <!--</v-list-item-content>-->
                        </v-list-item>
                    </v-list>
                </div>
            </v-col>
        </v-row>
        <div id="custom_script"></div>
    </div>
</template>
<script>
    export default {
        props: ['video', 'refresh'],
        name:"VideoPlay",
        data: ()=>({
            offset:0,
            player: null,
            transcriptArr: null,
            transcriptJson:null,
            transcriptDict:null,
        }),
        mounted(){
            //console.log("VideoPlay - mounted");
            this.init(this.video);
        },
        watch: {
            video: function (newVal) { // watch it
                //console.log("VideoPlay - watched");
                this.init(newVal);
            }
        },
        beforeDestroy(){
            //console.log("beforeDestroy");
            try{
                eval("clearAll")();
            }catch(e){
                console.log(e);
            }
        },
        
        methods: {
            init(video) {
                if (video) {
                    this.transcriptArr = video.transcript;
                    this.makeTranscript(video);
                }
                try {
                    if (!eval("getPlayer")()) {
                        //console.log('init player');
                        this.initStyle();
                        this.initYoutube();
                    } else {
                        //console.log('update player');
                        eval("revokePlayer")(video.youtubeId, this.transcriptDict);
                    }
                } catch{
                    //console.log('init page');
                    this.initStyle();
                    this.initYoutube();
                }
            },
            initStyle(){
                var link_tag = document.createElement('link');
                link_tag.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap');
                link_tag.setAttribute('ref', 'stylesheet');
                document.head.appendChild(link_tag);
            },
            initYoutube() {
                var tag = document.createElement('script');
                //tag.src = "https://www.youtube.com/player_api";
                tag.src = "https://www.youtube.com/iframe_api";
                tag.id = "ytb_script";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                //window.onYouTubeIframeAPIReady = () =>
                var custom_tag = document.createElement('script');
                //onYouTubePlayerAPIReady
                custom_tag.innerText = '\
                var player;\
                var tsInterval = null;\
                var gvideoId = "' + this.video.youtubeId + '";\
                var renewPlayer = false;\
                function onYouTubeIframeAPIReady() {\
                    player = new YT.Player("ytplayer", {\
                        height: "100%",\
                        width: "100%",\
                        videoId: gvideoId,\
                        events: {\
                        onReady: onPlayerReady,\
                        onStateChange: onPlayerStateChange\
                    }\
                    });\
                }\
                function onPlayerReady(event){\
                    event.target.playVideo();\
                }\
                function onPlayerStateChange(event){\
                    if (event.data == YT.PlayerState.PLAYING) {\
                        /*console.log("PlayerState.PLAYING");*/\
                        /*console.log("tsInterval:",tsInterval);*/\
                        if (!tsInterval){\
                            tsInterval = window.setInterval(refreshTs, 200);\
                        }\
                    }\
                    else if (event.data == YT.PlayerState.ENDED) {\
                        console.log("PlayerState.ENDED");\
                        clearInterval(tsInterval);\
                        tsInterval = null;\
                        transcriptViewer.innerText = "<Video đã kết thúc>";\
                    }\
                }\
                function revokePlayer(videoId, newtranscript){\
                    transcript = newtranscript;\
                    transcriptViewer = document.getElementById("tsviewer");\
                    if (!renewPlayer){\
                        window.clearInterval(tsInterval);\
                        tsInterval = null;\
                        /*console.log("change video Id");*/\
                        player.loadVideoById(videoId);\
                    }\
                    else{\
                        /*console.log("renew player");*/\
                        player = new YT.Player("ytplayer", {\
                            height: "100%",\
                            width: "100%",\
                            videoId: videoId,\
                            events: {\
                                onReady: onPlayerReady,\
                                onStateChange: onPlayerStateChange\
                            }\
                        });\
                        renewPlayer = false;\
                    }\
                }\
                function getPlayer(){\
                    return player;\
                }\
                function clearAll(){\
                    window.clearInterval(tsInterval);\
                    tsInterval = null;\
                    transcript = null;\
                    /*console.log("check DOM ytplayer", document.getElementById("ytplayer"));*/\
                    renewPlayer = true;\
                    /*player.stopVideo();*/\
                    /*player.destroy();*/\
                }\
                var transcript = ' + JSON.stringify(this.transcriptDict) + ';\
                var transcriptViewer = document.getElementById("tsviewer");\
                function refreshTs(){\
                    if (player){\
                        /*transcriptViewer.innerText = player.playerInfo.currentTime.toString();*/\
                        var current_offset = Math.floor(player.playerInfo.currentTime);\
                        /*transcriptViewer.innerText = current_offset.toString() + " ";*/\
                        if (transcript[current_offset]){\
                            transcriptViewer.innerHTML = "🔊 " + transcript[current_offset];\
                        }\
                    }else{\
                        transcriptViewer.innerText = offset.toString() + "No player";\
                    }\
                }';
                firstScriptTag.parentNode.appendChild(custom_tag);
            },
            addPlayScript(){

            },
            updateYoutube(){
                console.log("update youtube");
            },
            makeTranscript(video){
                //console.log('this.transcript:', this.transcript);
                var tmp_ = video.transcript;
                var tsDict = {};
                for (const element of tmp_) {
                    var time_ = Math.round(element["start"]);
                    if (!tsDict[time_]){
                        tsDict[time_] = element['caption'];
                    }else{
                        tsDict[time_] += element['caption'];
                    }
                } 
                this.transcriptArr = tmp_;
                this.transcriptDict = tsDict;
                //return JSON.parse(this.transcript);
                
                /*var data = {
                    "23": "Chế biến tự nhiên, còn được gọi là chế biến khô, là phương pháp lâu đời nhất được sử dụng để tách hạt cà phê từ quả cà phê tươi",
                    "27": "và nó vẫn thường được áp dụng tại nhiều quốc gia đang phát triển cà phê",
                    "31": "bao gồm cả Ethiopia, nơi cà phê Arabica được phát hiện lần đầu tiên.",
                    "40": "Vì ở Ethiopia xử lý tự nhiên được hoàn thiện đầu tiên, nên chúng ta sẽ",
                    "44": "ghé thăm một cơ sở ở Yirgacheffe trong video này để tìm hiểu cụ thể thêm về quy trình chế biến này.",
                    "59": "Cà phê được chế biến tự nhiên được cho là có hương vị trái cây hơn so với cà phê được chế biến theo những phương pháp khác.",
                    "64": "Hương vị có thể gợi nhớ đến trái cà phê tươi, việt quất, quả mâm xôi hoặc dâu tây.",
                    "69": "Lịch sử, chế biến tự nhiên hoặc chế biến khô đã được thực hành ở vùng khí hậu khô, ấm, và nắng, không có nhiều độ ẩm hoặc mây che phủ.	",
                    "77": "Ngày nay, kỹ thuật này đang được áp dụng bởi các nhà sản xuất trên toàn thế giới, những người quan tâm đến hương vị được tạo ra bởi ",
                    "82": "quá trình để  hạt trong quả cà phê với thời gian lâu hơn.",
                    "86": "Bây giờ chúng ta sẽ kiểm tra các bước làm trong quá trình chế biến tự nhiên cà phê.",
                    "89": "Đầu tiên, như trong mọi phương pháp xử lý khác, trái cà phê được hái chín và, ",
                    "95": "được giao cho một trạm rửa, cách gọi ở Ethiopia.",
                    "98": "Mặc dù chúng được gọi là trạm rửa ở Ethiopia, hầu hết các nhà máy vừa chế biến ướt vừa chế biến khô",
                    "104": "Sự khác biệt cơ bản giữa hai cách chế biến là thời gian bao lâu hạt cà phê vẫn còn ở trong quả cà phê của nó",
                    "110": "Ví dụ, \"hạt được rửa\" là một thuật ngữ thường được sử dụng để mô tả hạt cà phê ngay khi được lấy ra khỏi quả cà phê",
                    "114": "thông thường trong vòng 8 đến 12 giờ sau khi được thu hoạch.",
                    "121": "Ngược lại, cà phê chế biến tự nhiên được để lại trong toàn bộ trái cây của họ trong toàn bộ thời gian sấy,",
                    "124": "đây một phần của những yếu tố tạo ra hương vị khác nhau riêng biệt của chúng.",
                    "131": "Bởi vì hạt không được loại bỏ khỏi quả trong chế biến tự nhiên, nên quả cà phê có vai trò rất quan trọng",
                    "135": "chỉ những là quả chín, tươi nhất và phát triển nhất mới được sử dụng",
                    "143": "Việc sắp xếp phân loại được thực hiện tại các trạm rửa trong suốt quá trình vận chuyển, cũng như trong quá trình sấy khô",
                    "152": "Thay vì được được xay tách vỏ, lên men và rửa, cà phê được mang đến nhà máy xử lý quả cà phê",
                    "156": "và được sắp xếp phân loại, cân, và ghi lại trước khi di chuyển trực tiếp đến khu vực sấy khô.",
                    "164": "Ở Ethiopia, cà phê thường được sấy khô trên những chiếc sạp cao được đan hoặc làm thành dạng lưới ",
                    "168": "để cho phép không khí lưu thông xung quanh trái cây.",
                    "171": "Điều này cho phép sấy quả cà phê khô hơn và đồng đều hơn trên mỗi bàn,",
                    "175": "có thể bao gồm các lô riêng lẻ.",
                    "182": "Sấy khô tự nhiên có thể gặp khó khăn vì nhiều lý do.",
                    "184": "một trong số đó là, chất hữu cơ trong trái cây bắt đầu lên men một cách tự nhiên ngay khi vừa được hái",
                    "188": "và quá trình lên mên được đẩy nhanh khi quả tươi được đặt dưới sức nóng mặt trời",
                    "193": "Quả dễ bị nấm mốc và các loại khuyết tật khác có thể xảy ra trong thời gian này",
                    "197": "vì vậy nó đòi hỏi sự chú ý liên tục của nhân viên để xoay trở quả cà phê, và tiếp tục được sàng lọc chúng khi chúng khô.",
                    "209": "Sấy khô tự nhiên mất nhiều thời gian hơn chế biến ướt hoặc thậm chí chế biến mật. nó có thể mất ba đến bốn tuần ",
                    "213": "để trái cây khô hoàn toàn và độ ẩm của hạt bên trong đạt đến độ ẩm yêu cầu cho xuất khẩu, khoảng 11%",
                    "223": "Thường xuyên, để điều chỉnh và điều chỉnh quá trình sấy, công nhân sẽ chồng hoặc che",
                    "228": "cà phê tùy thuộc vào những gì cần thiết.",
                    "231": "Tại trạm giặt này, công nhân thậm chí còn chuyển quả cà phê sang một mặt phơi khác để kiểm soát tỉ lệ và tính đồng nhất của quá trình sấy khô",
                    "252": "Sau khi quả khô, nó được chuyển đến nhà máy khô, nơi hạt cà phê và quả của nó của nó sẽ được chạy qua một máy hờn dãi, ",
                    "258": "sử dụng ma sát để loại bỏ vỏ và lớp vỏ lụa của nó.",
                    "273": "Cà phê xanh kết quả được đưa đến một nhà máy để được sắp xếp phân loại bằng tay hoặc bởi các thiết bị đặc biệt,",
                    "277": "Và nó cũng sẽ được quạt sạch ở giai đoạn này.",
                    "281": "Sau đó, nó sẽ được đặt trong bao tải và được lưu trữ trong một nhà kho cho đến khi nó sẵn sàng để được vận chuyển."
                };
                return data;*/
            },
            getTimeLine(seconds){
                try{
                    var minute = Math.floor(Math.round(seconds) / 60);
                    var second = Math.round(seconds) % 60;
                    return String(minute).padStart(2, '0') + ":" + String(second).padStart(2, '0');
                }catch{
                    return ""
                }
            },
        },
    };
    /*window.onYouTubeIframeAPIReady = () => {
            console.log("onYouTubeIframeAPIReady");
            vm.$children[0].initYoutube();
    }*/
    //function getPlayer(){
        //getPlayer();
    //}
    /*$(function () {
        revokePlayer();
        getPlayer();
    })*/
    
</script>
<style>
    .news_detail{
        word-wrap: break-word;
        white-space: normal ;
        word-break: normal;
        overflow: hidden ;
        text-overflow: ellipsis;
    }
    .news_detail img{
        max-width: 100%;
    }
    .news_detail video{
        max-width: 100%;
    }
    .news_detail iframe{
        max-width: 100%;
    }
    .transcript-content{
        max-height: 400px;
        overflow: scroll;
        font-size: 14px;
        font-weight: 350;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .transcript-content::-webkit-scrollbar {
        display: none;
    }
    #tsviewer{
        height: fit-content;
        font-family: 'Bai Jamjuree', sans-serif !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        min-height: 88px;
    }
    #ytplayer{
        height: fit-content;
        min-height: 450px;
    }
    @media (max-width: 640px) {
        #ytplayer{
            height: fit-content;
            min-height: 300px;
        }
        .transcript-content{
            max-height: 300px;
            overflow: scroll;
        }
    }
</style>
