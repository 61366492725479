function fineTitle(breakpoint_name, in_text, maxLengthDef){ 
    var result = '';
    if (in_text){
        var raw_string = in_text;
        var maxLength = 0;
        switch (breakpoint_name){
            case 'xs': maxLength = (maxLengthDef[0]? maxLengthDef[0]: 30); break;
            case 'sm': maxLength = (maxLengthDef[1]? maxLengthDef[1]: 30); break;
            case 'md': maxLength = (maxLengthDef[2]? maxLengthDef[2]: 40); break;
            case 'lg': maxLength = (maxLengthDef[3]? maxLengthDef[3]: 40); break;
            case 'xl': maxLength = (maxLengthDef[4]? maxLengthDef[4]: 40); break;
        }
        var i =0;
        for (i = 0; i < 5; i++) {
            if (raw_string.length > maxLength) {
                var idx = raw_string.slice(0, maxLength).lastIndexOf(' ');
                result += raw_string.slice(0, idx) + "<br />";
                raw_string = raw_string.slice(idx);
            } else {
                result += raw_string;
                break;
            }
        }
    }
    return result;
}
function getBriefContent(breakpoint_name, in_text){ 
    var raw_string = in_text;
    var maxLength = 0;
    var result = '';
    switch (breakpoint_name) {
        case 'xs': maxLength = 120; break;
        case 'sm': maxLength = 160; break;
        case 'md': maxLength = 360; break;
        case 'lg': maxLength = 360; break;
        case 'xl': maxLength = 360; break;
    }
    if (raw_string.length > maxLength) {
        var idx = raw_string.slice(0, maxLength).lastIndexOf(' ');
        result += raw_string.slice(0, idx) + " ...";
        //raw_string = raw_string.slice(idx);
    }else
        result = raw_string;
    return result;
}

function nomalizeText(text){
    return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/đ/g, 'd').replace(/Đ/g, 'D').replace(/ /g,'_').replace(/,/g,'');
}
function createGotoText(tabName, controlId){
    return tabName + "@" + controlId;
}
function parseGotoText(navigateText){
    var texts = navigateText.split("@");
    //console.log("[parseGotoText]", texts);
    if (texts.length >= 2){
        return {tabName: texts[0], controlId: texts[1]};
    }
    else{
        return null;
    }
}
function quickdef(shortcut){
    var tmp_ = shortcut.split("#");
    if (tmp_.length == 2){
        return '<a href="#" data-modal="#modal" data-src="' + tmp_[0] + 
        '" onclick="return requestQuickDef(this)" class="modal__trigger" style="transform: none;">' + tmp_[1] + '</a>';
    }
    return null;
}
function convert_quickdef(a_link){
    var regex = /<a.*?data-modal="#modal".*? data-src="(.*?)" .*?>(.*?)<\/a>/g;
    var tmp_ = regex.exec(a_link);
    //var tmp_ = a_link.matchAll(/<a.*?data-modal="#modal".*?data-src="(.*?)>(.*?)<\/a>/g);
    //console.log("tmp_:", tmp_);
    if (tmp_.length == 3){
        return '@LOKAQDEF' + tmp_[1] + "#" + tmp_[2] + 'LOKAQDEF@';
    }
    return null;
}
export {fineTitle, getBriefContent, nomalizeText, createGotoText, parseGotoText, quickdef, convert_quickdef};
