<template>
<v-container class="text-center pa-0 ma-0">
  <v-dialog v-model="loading" persistent max-width="600">
    <v-card class="pa-4">
      <v-card-text>
        <span class="my-2">Vui lòng chờ trong giây lát.</span>
      </v-card-text>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-card>
  </v-dialog>
    <div v-if="shopDetail" id="shop_div">
      <v-card flat rounded class="text-left" :color="colorSet.overallColor">
        <v-toolbar :color="colorSet.background" light flat height="100px">
          <!--<v-toolbar-title class="pt-4"><h3 v-text="getDisplayName" class="font-weight-bold white--text"></h3>-->
            <v-toolbar-title class="pt-4" :class="colorSet.textColor"><span v-text="getDisplayName" class="shop_beautiful_banner"></span><br/>
            <template v-if="shopDetail.info.introduction">
            <v-icon small :class="colorSet.foreColor" class="mr-2">mdi-format-quote-open-outline</v-icon>
            <span class="intro_text" :class="colorSet.textColor">{{shopDetail.info.introduction}}</span>
            <v-icon small :class="colorSet.textColor" class="ml-2">mdi-format-quote-close-outline</v-icon>
          </template>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title center-active next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline" background-color="transparent"
            :color="colorSet.foreColor" show-arrows>
              <v-tabs-slider color="orange lighten-3"></v-tabs-slider>
              <v-tab href="#tab_shop" v-if="shopDetail">
                <span :class="colorSet.textColor" class="tab_title">{{tabName["tab_shop"]}}</span>
              </v-tab>
              <v-tab href="#tab_product" v-if="productList && productList.length > 0">
                <span :class="colorSet.textColor" class="tab_title">{{tabName["tab_product"]}}</span>
              </v-tab>
              <v-tab href="#tab_fqa" v-if="fqaList && fqaList.length > 0">
                <span :class="colorSet.textColor" class="tab_title">{{tabName["tab_fqa"]}}</span>
              </v-tab>
              <v-tab href="#tab_news" v-if="news && news.length > 0">
                <span :class="colorSet.textColor" class="tab_title">{{tabName["tab_news"]}}</span>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab" touchless >
          <v-tab-item :key="1" value="tab_shop" v-if="shopDetail">
            <v-card flat class="pt-0" :color="colorSet.overallColor">
                <shop-detail v-model="shopDetail" :common="common" :navigate="navigate" :gotoX="gotoShop" :leaveX="leaveShop" class="m6"></shop-detail>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="2" value="tab_product" v-if="productList && productList.length > 0">
            <v-card flat class="pt-2 px-0" :color="colorSet.overallColor">
              <product-overview :products="productList" :shopInfo="shopDetail" :common="common" :navigate="navigate" :gotoX="gotoProduct" :leaveX="leaveProduct"></product-overview>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="3" value="tab_fqa" v-if="fqaList && fqaList.length > 0">
            <v-card flat class="pt-2" :color="colorSet.overallColor">
              <fqa-list :fqas="fqaList" :shopInfo="shopDetail" :common="common" :navigate="navigate" :gotoX="gotoFQA" :leaveX="leaveFQA" ref="fqa_component"></fqa-list>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="4" value="tab_news" v-if="news && news.length > 0">
            <v-card flat class="pt-2" :color="colorSet.overallColor">
              <news-list :news="news" :shopInfo="shopDetail" :common="common" :navigate="navigate" :gotoX="gotoNews" :leaveX="leaveNews"></news-list>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-divider class="my-4"></v-divider>
        <v-sheet :color="colorSet.background" class=" text-center justify-center" :class="colorSet.textColor">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" md="12" class="py-0 px-2 text-left">
              <v-card-subtitle class="px-4 py-2" :class="colorSet.textColor">
              <span v-text="getDisplayName" class="font-weight-medium"></span>
            </v-card-subtitle>
            </v-col>
            <v-col cols="12" md="6" class="py-0 px-2 text-left">
              <v-card-text class="py-1">
              <span class="font-weight-medium">Địa chỉ: {{shopDetail.info.address}}</span>
            </v-card-text>
            </v-col>
            <v-col v-if="shopDetail.info.phoneNumber[0]" cols="12" md="6" class="py-0 px-2 text-left">
              <v-card-text class="py-1">
              <span class="font-weight-medium">Điện thoại: {{shopDetail.info.phoneNumber[0]}}</span>
            </v-card-text>
            </v-col>
            <v-col cols="12" md="12" class="pa-0 ma-1" >
              <v-btn class="text-none" text :class="colorSet.textColor"
                                @click="$vuetify.goTo('#shop_div');">Lên đầu trang 
                <v-icon small color="white" class="ml-2" :class="colorSet.textColor">mdi-arrow-up-bold-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card>
      <fab_util :gotoEvent='navigate' :nav_list="getNavList" :suggest_list="suggestionsTopN"></fab_util>
      <menu_util v-if="finishGetData == 4" :gotoEvent='navigate' :menus="getMenus"></menu_util>
    </div>
    <div v-if="!loading && !shopDetail" class="error_screen">
      <v-card class="pa-4">
        <v-card-text>
          <v-icon medium class="red--text pb-2 pr-2">mdi-alert</v-icon>
          <span class="red--text">Đã có lỗi xảy ra</span>
          <br>
          <span class="my-2">Không tìm thấy cửa hàng với mã {{this.$route.params.id}}. Bạn vui lòng kiểm tra lại mã cửa hàng.</span>
        </v-card-text>
      </v-card>
    </div>
  <!--<div v-else class="text-center">
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
    
  </div>-->
</v-container>
</template>
<script>
  import ShopDetail from "../components/ShopDetail.vue";
  import ProductOverview from "../components/ProductOverview.vue";
  import FqaList from "../components/FqaList.vue";
  import NewsList from "../components/NewsList.vue";
  import DataService from "../services/DataService.js";
  import {fineTitle} from "../utils/text_utils.js";
  import { mapGetters, mapMutations } from 'vuex';
  import FabUtil from "../components/FabUtil.vue";
  import MenuUtil from "../components/MenuUtil.vue";
  import { nomalizeText, createGotoText} from "../utils/text_utils.js";
  export default {
    props: ['common'],
    name: 'Shop',
    components: {
      'shop-detail': ShopDetail,
      'product-overview': ProductOverview,
      'fqa-list': FqaList,
      'news-list': NewsList,
      'fab_util': FabUtil,
      'menu_util': MenuUtil,
    },
    data: () => ({
      loading: true,
      selection: 1,
      shopDetail: null,
      shopChatInfo: null,
      productList:[],
      fqaList:[],
      news:[],
      tab:"tab_shop",
      selected_prod: null,
      //show_prod_detail: false,
      fab:false,
      gotoFQA: null,
      gotoShop: null,
      gotoProduct: null,
      gotoNews: null,
      leaveShop: null,
      leaveProduct: null,
      leaveFQA: null,
      leaveNews: null,
      navDic: {'tab_shop':[], 'tab_product':[], 'tab_fqa':[], 'tab_news':[]},
      tabName: {'tab_shop':'Cửa hàng', 'tab_product':'Sản phẩm', 'tab_fqa':'Hỏi & Đáp', 'tab_news':'Tin tức'},
      finishGetData: 0,
    }),
    mounted(){
      this.getShop(this.$route.params.id);

      //Add facebook pixel script
      this.addFacebookPixel(this.$route.params.id, 'facebook_pixel');

      //Add messenger chat
      this.addMessengerChat(this.$route.params.id, 'messenger');

    },
    computed:{
      getDisplayName() {
        return fineTitle(this.$vuetify.breakpoint.name, this.shopDetail.info.displayName, [30,30,40,40,40]);
      },
      ...mapGetters([
        'shopId',
        'suggestionsTopN',
        'colorSet',
        'storedHistory',
      ]),
      getNavList(){
        return this.navDic[this.tab];
      },
      getMenus(){
        var menus = [];
        if (this.navDic['tab_shop'].length > 0){
          menus.push({'title':this.tabName['tab_shop'], 'nav_list':this.navDic['tab_shop']});
        }
        if (this.navDic['tab_product'].length > 0){
          menus.push({'title':this.tabName['tab_product'], 'nav_list':this.navDic['tab_product']});
        }
        if (this.navDic['tab_fqa'].length > 0){
          menus.push({'title':this.tabName['tab_fqa'], 'nav_list':this.navDic['tab_fqa']});
        }
        if (this.navDic['tab_news'].length > 0){
          menus.push({'title':this.tabName['tab_news'], 'nav_list':this.navDic['tab_news']});
        }
        return menus;
      },
    },
    methods: {
          ...mapMutations([
            'createShopId',
            'addStoredHistory',
          ]),
          getShop(id) {
            //console.log(`getShop() with id = ${id}`);
            DataService.getShop(id)
              .then(async (response) => {
                if (!response.data.result || !response.data.result["id"]){
                  this.loading = false;
                  return null;
                }
                this.shopDetail = { info: response.data.result, userRelation: {} };
                if (('tab_config' in this.shopDetail.info) && (this.shopDetail.info['tab_config'])){
                  if (this.shopDetail.info['tab_config']['tab_shop'])
                    this.tabName['tab_shop'] = this.shopDetail.info['tab_config']['tab_shop'];
                  if (this.shopDetail.info['tab_config']['tab_product'])
                    this.tabName['tab_product'] = this.shopDetail.info['tab_config']['tab_product'];
                  if (this.shopDetail.info['tab_config']['tab_fqa'])
                    this.tabName['tab_fqa'] = this.shopDetail.info['tab_config']['tab_fqa'];
                  if (this.shopDetail.info['tab_config']['tab_news'])
                    this.tabName['tab_news'] = this.shopDetail.info['tab_config']['tab_news'];
                }
                this.loading = false;
                //init store params
                this.createShopId({ shopId: this.$route.params.id, theme: response.data.result.theme });

                //Add history
                this.addStoredHistory("StartJourney");

                this.updateWebTitle(this.shopDetail.info.displayName);
                if (response.data.result.details) {
                  await Promise.all(response.data.result.details.map(element => {
                    this.navDic['tab_shop'].push({ tag: element.label, destination: createGotoText("tab_shop", nomalizeText(element.label)) });
                  }));
                }
                this.finishGetData += 1;

                //Move to default tab
                setTimeout(() => {
                  if (this.shopDetail.info['tab_config']['default_tab']) {
                    switch (this.shopDetail.info['tab_config']['default_tab']) {
                      case 0: this.tab = 'tab_shop'; break;
                      case 1: this.tab = 'tab_product'; break;
                      case 2: this.tab = 'tab_fqa'; break;
                      case 3: this.tab = 'tab_news'; break;
                    }
                  }
                }, 500);
              })
              .catch((e) => {
                console.log(e);
              });

            //get product list
            DataService.getProductList(id).then(async (result) => {
              //console.log('result.data:', result.data);
              this.productList = result.data.result;
              if (this.productList) {
                await Promise.all(result.data.result.map((element) => {
                  this.navDic['tab_product'].push({ tag: element.type, parent_group: element.parent_type, destination: createGotoText("tab_product", nomalizeText(element.type)) });
                }));
              }
              this.finishGetData += 1;
            }).catch(function (error) {
              console.log(error);
            });

            //get fqa list
            DataService.getFqaList(id).then(async (result) => {
              //console.log('result.data:', result.data);
              this.fqaList = result.data.result;
              if (this.fqaList) {
                await Promise.all(this.fqaList.map(element => {
                  //sort docs list
                  if (element.topics) {
                    element.topics.sort(function (a, b) { return b.weight - a.weight });
                    //sort fqa topics
                    /*element.topics.forEach(topic => {
                      if (topic.docs) {
                        topic.docs.sort(function (a, b) { return a.position - b.position });
                      }
                    })*/
                  }
                  this.navDic['tab_fqa'].push({ tag: element.question, destination: createGotoText("tab_fqa", element.id) });
                }));
              }
              this.finishGetData += 1;
            }).catch(function (error) {
              console.log(error);
            });

            //get news list
            DataService.getNewsList(id, 20).then(async (result) => {
              //console.log('result.data:',result.data);
              this.news = result.data.result;
              if (this.news) {
                await Promise.all(this.news.map(element => {
                  this.navDic['tab_news'].push({ tag: element.title, destination: createGotoText("tab_news", element.id) });
                }));
              }
              this.finishGetData += 1;
            }).catch(function (error) {
              console.log(error);
            });
          
          },
          calHeight() {
            var height = "200px";
            switch (this.$vuetify.breakpoint.name) {
              case 'xs': height = "150px"; break;
              case 'sm': height = "150px"; break;
              case 'md': height = "150px"; break;
              case 'lg': height = "150px"; break;
              case 'xl': height = "150px"; break;
            }
            return height;
          },
          updateWebTitle(title) {
            if (title) {
              document.title = title;
            }
          },
          navigate(navigateText) {
            //console.log('navigateText:', navigateText);
            var texts = navigateText.split("@");
            if (texts.length == 2) {
              var tabName = texts[0];
              //inform to leave tab
              switch (this.tab) {
                      case "tab_shop": this.leaveShop = this.toggleEvent(this.leaveShop, "tab_shop"); break;
                      case "tab_product": this.leaveProduct = this.toggleEvent(this.leaveProduct, "tab_product"); break;
                      case "tab_fqa": this.leaveFQA = this.toggleEvent(this.leaveFQA, "tab_fqa"); break;
                      case "tab_news": this.leaveNews = this.toggleEvent(this.leaveNews, "tab_news"); break;
                    }
              if (this.tab != tabName) {
                //change display tab
                this.tab = tabName;
                
                //goto within tab
                this.$nextTick(() => {
                  setTimeout(() => {
                    switch (tabName) {
                      case "tab_shop": this.gotoShop = this.updateGotoTarget(this.gotoShop, navigateText); break;
                      case "tab_product": this.gotoProduct = this.updateGotoTarget(this.gotoProduct, navigateText); break;
                      case "tab_fqa": this.gotoFQA = this.updateGotoTarget(this.gotoFQA, navigateText); break;
                      case "tab_news": this.gotoNews = this.updateGotoTarget(this.gotoNews, navigateText); break;
                    }
                  }, 1000);
                });
              } else {
                switch (tabName) {
                  case "tab_shop": this.gotoShop = this.updateGotoTarget(this.gotoShop, navigateText); break;
                  case "tab_product": this.gotoProduct = this.updateGotoTarget(this.gotoProduct, navigateText); break;
                  case "tab_fqa": this.gotoFQA = this.updateGotoTarget(this.gotoFQA, navigateText); break;
                  case "tab_news": this.gotoNews = this.updateGotoTarget(this.gotoNews, navigateText); break;
                }
              }
            }
          },
          updateGotoTarget(target, controlId) {
            if (target && target.includes("@1"))
              return (controlId + "@2");
            else
              return controlId + "@1";
          },
          //change value of event
          toggleEvent(event, base){
            if (event) {
              if (event == base + "@1") {
                event = base + "@2";
              } else {
                event = base + "@1";
              }
            }else{
              event = base + "@1";
            }
            return event;
          },
          async addFacebookPixel(shopId, type) {
            let trackingSystem = await DataService.getTrackingSystem(shopId, type);
            var tracking_id = "";
            try {
              if (trackingSystem['status'] == 200){
                tracking_id = trackingSystem['data']['result'][0]['tracking_id'];
              }
            } catch(e){
              tracking_id = "";
              console.log(e);
            }
            if (tracking_id != "") {
              //Add additional script to <head>
              let fb_pixel_ = document.createElement('script');
              fb_pixel_.innerText = `!function(f,b,e,v,n,t,s)\
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};\
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";\
  n.queue=[];t=b.createElement(e);t.async=!0;\
  t.src=v;s=b.getElementsByTagName(e)[0];\
  s.parentNode.insertBefore(t,s)}(window, document,"script",\
  "https://connect.facebook.net/en_US/fbevents.js");\
  fbq("init", "${tracking_id}");\
  fbq("track", "PageView");`;
              let noscript_ = document.createElement('noscript');
              let img_ = document.createElement('img');
              img_.setAttribute('height', '1');
              img_.setAttribute('width', '1');
              img_.setAttribute('style', 'display:none');
              img_.setAttribute('src', `https://www.facebook.com/tr?id=${tracking_id}&ev=PageView&noscript=1`);
              noscript_.appendChild(img_);
              document.head.appendChild(fb_pixel_);
              document.head.appendChild(noscript_);
            }
          },
          async addMessengerChat(shopId, type){
            let chatPlugin = await DataService.getChatPlugin(shopId, type);
            var plugin_id = "";
            try {
              if (chatPlugin['status'] == 200){
                plugin_id = chatPlugin['data']['result'][0]['plugin_id'];
              }
            } catch(e){
              plugin_id = "";
              console.log(e);
            }
            if (plugin_id != "") {
              let messenger_div = document.createElement('div');
              messenger_div.innerHTML = '<div id="fb-root"></div>' +
                '<div id="fb-customer-chat" class="fb-customerchat">' +
                '</div>';
              let messenger_script = document.createElement('script');
              messenger_script.innerText = 'var chatbox = document.getElementById("fb-customer-chat");' +
                'chatbox.setAttribute("page_id", "'+ plugin_id +'");' +
                'chatbox.setAttribute("attribution", "biz_inbox");' +
                'window.fbAsyncInit = function () {' +
                'FB.init({' +
                'xfbml: true,' +
                'version: "v11.0"' +
                '});' +
                '};' +

                '(function (d, s, id) {' +
                '    var js, fjs = d.getElementsByTagName(s)[0];' +
                '    if (d.getElementById(id)) return;' +
                '    js = d.createElement(s); js.id = id;' +
                '    js.src = "https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js";' +
                '    fjs.parentNode.insertBefore(js, fjs);' +
                '}(document, "script", "facebook-jssdk"));';
              document.body.appendChild(messenger_div);
              document.body.appendChild(messenger_script);
            }
          },
        },
  }
</script>
<style>
  .text-caption{
      font-family: Tahoma, none;
    }
  .intro_text{
    font-size:14px;
    font-weight: 400;
    color: #FFFFFF !important;
  }
  .tab_title{
    text-transform: capitalize;
    font-size:16px;
  }
</style>
