<template>
    <div class="pa-0 ma-0">
        <div class="px-2 filter_component">
        <template v-if="features['manufacturer'].values.length > 1">
            <span class="filter_title font-weight-light">{{features['manufacturer'].title}}</span>
            <v-chip-group v-model="filter_manu" column multiple :color="colorSet.background">
                <v-chip small label outlined class="mr-2 my-1 shop-intro"
                    v-for="(manu_tag, index) in features['manufacturer'].values" :key="index">
                    {{manu_tag}}
                </v-chip>
            </v-chip-group>
        </template>
        <template v-if="features['filter_attribute1'].values.length > 1">
            <span class="filter_title font-weight-light">{{features['filter_attribute1'].title}}</span>
            <v-chip-group v-model="filter_attrb1" column multiple :color="colorSet.background">
                        <v-chip small label outlined class="mr-2 my-1 shop-intro"
                    v-for="(attrb_tag, index) in features['filter_attribute1'].values" :key="index">
                    {{attrb_tag}}
                </v-chip>
            </v-chip-group>
        </template>
        
        <template v-if="features['filter_attribute2'].values.length > 1">
            <span class="filter_title font-weight-light">{{features['filter_attribute2'].title}}</span>
            <v-chip-group v-model="filter_attrb2" column multiple :color="colorSet.background">
                <v-chip small label outlined class="mr-2 my-1 shop-intro"
                    v-for="(attrb_tag, index) in features['filter_attribute2'].values" :key="index">
                    {{attrb_tag}}
                </v-chip>
            </v-chip-group>
        </template>
        
        <template v-if="features['filter_attribute3'].values.length > 1">
            <span class="filter_title font-weight-light">{{features["filter_attribute3"].title}}</span>
            <v-chip-group v-model="filter_attrb3" column multiple :color="colorSet.background">
                <v-chip small label outlined class="mr-2 my-1 shop-intro"
                    v-for="(attrb_tag, index) in features['filter_attribute3'].values" :key="index">
                    {{attrb_tag}}
                </v-chip>
            </v-chip-group>
        </template>
    </div>
        <v-list color="transparent">
            <template v-for="(product, index) in products" >
                <template v-if="!product.hidden">
                    <v-divider inset :key="index" v-if="index > 0"></v-divider>
                    <v-list-item :key="product.id" @click="selectProdEvent(product)" :id="'id-' + product.id">
                        <v-list-item-avatar tile size="50">
                            <v-img :src="product.images[0]"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-html="product.product_name"></v-list-item-title>
                            <v-list-item-subtitle v-if="product.manufacturer">Nhà sx: {{product.manufacturer}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Giá: {{product.price}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </template>
        </v-list>
    </div>
</template>
<script>
    //import {roomsRef, firebaseAuth, dbTimestamp} from '@/firestore';
    import {firebaseAuth} from '@/firestore';
    import DataService from "../services/DataService.js";
    import { mapGetters, mapMutations } from 'vuex';
    import { nomalizeText, createGotoText, parseGotoText} from "../utils/text_utils.js";

    export default {
        props: ['products', 'selectProdEvent', 'gotoX'],
        data: ()=>({
            features : {'manufacturer':{'title':'Nhà sản xuất', 'values': []}, 'price_class':{'title':'Giá tiền', 'values': []}, 
            'filter_attribute1':{'title':'', 'values': []}, 'filter_attribute2':{'title':'', 'values': []}, 'filter_attribute3':{'title':'', 'values': []}},
            filter_manu : [],
            filter_attrb1:[],
            filter_attrb2:[],
            filter_attrb3:[],
        }),
        
        mounted(){
            //console.log('this.products:', this.products);
            setTimeout(() => {
                if (this.gotoX) {
                    var tmp = parseGotoText(this.gotoX);
                    if (tmp && tmp.controlId) {
                        this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                        this.$vuetify.goTo('#id-' + tmp.controlId, {offset:100});
                    }
                }
            }, 300);
            this.$watch('gotoX', this.gotoXWatcher);

            //analyze features
            this.products.forEach(element => {
                if (!element.manufacturer){
                    element.manufacturer = 'N/A';
                }
                if (this.features['manufacturer'].values.indexOf(element.manufacturer) == -1){
                        this.features['manufacturer'].values.push(element.manufacturer);
                        this.filter_manu.push(this.features['manufacturer'].values.length - 1);
                    }
                
                if (element.filter_attribute1_label && this.features['filter_attribute1'].title == ""){
                    this.features['filter_attribute1'].title = element.filter_attribute1_label;
                }
                if (!element.filter_attribute1){
                    element.filter_attribute1 = 'N/A';
                }
                if (this.features['filter_attribute1'].values.indexOf(element.filter_attribute1) == -1){
                        this.features['filter_attribute1'].values.push(element.filter_attribute1);
                        this.filter_attrb1.push(this.features['filter_attribute1'].values.length - 1);
                    }

                if (element.filter_attribute2_label && this.features['filter_attribute2'].title == ""){
                    this.features['filter_attribute2'].title = element.filter_attribute2_label;
                }
                if (!element.filter_attribute2){
                    element.filter_attribute2 = 'N/A';
                }
                if (this.features['filter_attribute2'].values.indexOf(element.filter_attribute2) == -1){
                        this.features['filter_attribute2'].values.push(element.filter_attribute2);
                        this.filter_attrb2.push(this.features['filter_attribute2'].values.length - 1);
                    }

                if (element.filter_attribute3_label && this.features['filter_attribute3'].title == ""){
                    this.features['filter_attribute3'].title = element.filter_attribute3_label;
                }
                if (!element.filter_attribute3){
                    element.filter_attribute3 = 'N/A';
                }
                if (this.features['filter_attribute3'].values.indexOf(element.filter_attribute3) == -1){
                        this.features['filter_attribute3'].values.push(element.filter_attribute3);
                        this.filter_attrb3.push(this.features['filter_attribute3'].values.length - 1);
                    }
            })
        },
        computed: {
            ...mapGetters([
                'storedHistory',
                'colorSet',
            ]),
        },
        watch:{
            filter_manu: async function (newValue) {
                if (newValue){
                    var tags = [];
                    await Promise.all(newValue.map(element => {
                        tags.push(this.features['manufacturer'].values[element]);
                    }));
                
                    this.filter('manufacturer', tags);
                }
            },

            filter_attrb1: async function (newValue) {
                if (newValue){
                    var tags = [];
                    await Promise.all(newValue.map(element => {
                        tags.push(this.features['filter_attribute1'].values[element]);
                    }));
                
                    this.filter('filter_attribute1', tags);
                }
            },

            filter_attrb2: async function (newValue) {
                if (newValue){
                    var tags = [];
                    await Promise.all(newValue.map(element => {
                        tags.push(this.features['filter_attribute2'].values[element]);
                    }));
                
                    this.filter('filter_attribute2', tags);
                }
            },

            filter_attrb3: async function (newValue) {
                if (newValue){
                    var tags = [];
                    await Promise.all(newValue.map(element => {
                        tags.push(this.features['filter_attribute3'].values[element]);
                    }));
                
                    this.filter('filter_attribute3', tags);
                }
            },
        },
        methods:{
            ...mapMutations([
                'addStoredHistory',
            ]),
            gotoXWatcher(newValue){
                var tmp = parseGotoText(newValue);
                if (tmp && tmp.controlId){
                    this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                    this.$vuetify.goTo('#id-'+ tmp.controlId, {offset:100});
                    this.products.forEach(element => {
                        element.products.forEach(prod => {
                            if (prod.id == tmp.controlId)
                                this.showProdDetail(prod);
                        })
                    });
                }
            },
            nomalizeText(text){
                return nomalizeText(text);
            },
            async chatAbout(product){
                //console.log('chatAbout');
                if(!firebaseAuth.currentUser){
                    //alert('you must login first');
                    //console.log('you must login first');
                    this.common();
                }else{
                    console.debug('this.shopInfo: ', this.shopInfo);
                    console.debug('product: ', product);
                    var userId = firebaseAuth.currentUser.uid;
                    var displayName = firebaseAuth.currentUser.displayName;
                    var shopId = this.shopInfo.info.id;
                    var productId = product.product_id;
                    var r_find = await DataService.findChatRoom(userId, shopId, productId);
                    var roomId = null;
                    //console.log('r_find: ', r_find);
                    if(r_find.data.room){
                        roomId = r_find.data.room.id;
                    }
                    console.log('roomId: ', roomId);
                    if(!roomId){//room not found
                        var room_name = `[${this.shopInfo.info.displayName}] - Liên hệ về ${product.product_name}`;
                        console.log('room_name: ', room_name)
                        var r_create = await DataService.createChatRoom(userId, shopId, null, this.shopInfo.info.staffs, room_name);
                        if(r_create)
                            roomId = r_create.data.roomId;
                    }
                    console.log('roomId: ', roomId);
                    if (roomId) {
                        this.$router.push({
                            name: "Chat",
                            params: { roomId: roomId, roomname: room_name, nickname: displayName }
                        });
                    }
                }
            },
            
            isMobile(){
                var isMobile = true;
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': isMobile = true; break;
                    case 'sm': isMobile = true; break;
                    case 'md': isMobile = false; break;
                    case 'lg': isMobile = false; break;
                    case 'xl': isMobile = false; break;
                }
                return isMobile;
            },
            calImageHeight(){
                var height = "200px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': height = "200px"; break;
                    case 'sm': height = "200px"; break;
                    case 'md': height = "250px"; break;
                    case 'lg': height = "250px"; break;
                    case 'xl': height = "250px"; break;
                }
                return height;
          },
          filter(type, items){
            this.products.forEach((element, index) =>{
                if (items.indexOf('all') != -1){
                    element.hidden = false;
                }
                else{
                    if (items.indexOf(element[type]) == -1){
                        element.hidden = true;
                    }
                    else{
                        element.hidden = false;
                    }
                }
                this.$set(this.products, index, element);
            })
          },
        }
    }
</script>
<style>
    
</style>
