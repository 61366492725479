<template>
    <div class="pa-0 ma-0" v-if="processed_prods">
        <!--<v-row class="pa-0 ma-0">-->
        <div v-for="(parent_type, pt_index) in processed_prods" :key="pt_index" >
            <v-banner v-if="parent_type[0]" class="text-center expansion_panel_title" :class="colorSet.textColor">{{parent_type[0]}}</v-banner>
            <v-expansion-panels multiple v-model="panels" light flat tile :readonly="true" :id="'v-expansion-panels_' + pt_index">
                <v-expansion-panel v-for="(prodType, index) in parent_type[1]['type_list']" :key="index" class="pa-0 ma-0">
                    <v-expansion-panel-header light :id="'id-' + nomalizeText(prodType.type)" :color="colorSet.background"
                        :class="colorSet.textColor" class="text-left" :ref="nomalizeText(prodType.type)">
                        <!--<v-icon class="white--text">mdi-dots-square</v-icon>-->
                        <span class="text-capitalize expansion_panel_title" :class="colorSet.textColor">
                            <v-icon class="mr-1" :class="colorSet.textColor">mdi-dots-square</v-icon>{{prodType.type}} ({{prodType.products.length}})
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-left pa-0 ma-0">
                        <template v-if="!isMobile() || !viewAsList">
                            <v-row class="pa-0 ml-0 mr-0 my-0">
                                <v-col cols="6" md="3" class="px-1" v-for="(product, index2) in prodType.products"
                                    :key="index2">
                                    <v-card :loading="false" class="pa-0 ma-0" light flat tile :id="'id-' + product.id">
                                        <v-row class="pa-0 ma-0" dense no-gutters justify="center">
                                            <v-col class="pa-0 ma-0" cols="12" md="12">
                                                <v-card-text class="pa-2">
                                                    <div class="headerClass2" @click="viewProd(product)">
                                                        {{product.product_name}}
                                                    </div>
                                                </v-card-text>
                                            </v-col>
                                            <!--<v-col class="pa-0 ma-0" cols="4" md="4">
                                                <v-card-text class="ma-0 pl-0 pr-2 py-2 text-right">
                                                    <div class="priceClass">
                                                        <v-icon x-small color="orange darken-4">mdi-cash-multiple</v-icon>
                                                        {{product.price}}
                                                    </div>
                                                </v-card-text>
                                            </v-col>-->
                                        </v-row>
                                        <!--<v-img :height="calImageHeight()" :src="product.image_link" gradient="to center, rgba(0,0,0,.7), rgba(0,0,0,.0)"></v-img>-->
                                        <!--<v-lazy :options="{threshold: 0.5}" transition="fade-transition">-->
                                            <v-img class="align-start justify-end" @click="viewProd(product)"
                                                :height="calImageHeight()" max-height="250px" :src="product.images[0]"
                                                lazy-src="/image-filter-black-white_128.png"
                                                gradient="to bottom, rgba(176,190,197,.1), rgba(176,190,197,.0)">
                                                <v-sheet class="priceClass pa-0 ma-0" :color="colorSet.backgroundTrans"
                                                    :class="colorSet.textColor">
                                                    <v-card-text class="ma-0 pl-2 pr-2 py-0 text-right">
                                                        <v-icon v-if="product.price" x-small color="orange darken-4">
                                                            mdi-cash-multiple</v-icon>
                                                        {{product.price}}
                                                    </v-card-text>
                                                </v-sheet>
                                            </v-img>
                                        <!--</v-lazy>-->
                                        <v-card-actions class="text-right py-1">
                                            <v-row class="pa-0 ma-0" dense no-gutters justify="center">
                                                <v-col class="pa-0 ma-0 text-center" cols="6" md="6">
                                                    <v-btn x-small text tile @click="viewProd(product)"
                                                        color="blue-grey darken-3">
                                                        Chi tiết
                                                        <v-icon x-small color="blue-grey darken-3" class="ml-1">
                                                            mdi-details
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col class="pa-0 ma-0 text-center" cols="6" md="6">
                                                    <v-btn v-if="chat_feature" x-small text tile @click="chatAbout(product)"
                                                        color="blue-grey darken-3">
                                                        Chat
                                                        <v-icon x-small color="blue-grey darken-3" class="ml-1">
                                                            mdi-message-text
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-else>
                            <products-list :products="prodType.products" :selectProdEvent="viewProd"></products-list>
                        </template>
                        <v-sheet class="mb-2 mx-1 white" rounded v-if="loadSuggestion">
                            <v-row class="ma-2">
                                <v-col cols="12" md="6" class="px-2 py-0"
                                    v-for="(item, index) in suggestions[nomalizeText(prodType.type)]" :key="index">
                                    <v-icon small color="yellow">mdi-arrow-decision-outline</v-icon>
                                    <a class="suggestionClass ml-1" @click="navigate(item.destination)">{{item.tag}}</a>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <!--</v-row>-->
        <!--<template v-if="selected_prod">-->
        <v-dialog v-if="show_prod_detail" :value="true" @input="show_prod_detail = false"
            :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="1200px" class="product_dialog">
            <template v-if="selected_prod">
                <v-toolbar dense dark :color="colorSet.background" :class="colorSet.textColor">
                    <v-btn icon dark @click="show_prod_detail = false;" :class="colorSet.textColor">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{selected_prod.product_name}}</v-toolbar-title>
                </v-toolbar>
                <v-card class="pb-2">
                    <product-detail :product="selected_prod"></product-detail>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="6" md="6" class="text-left">
                                <v-btn class="text-capitalize font-italic text-caption" :color="colorSet.buttonColor" text
                                    @click="getUrlToProd(selected_prod.id)">
                                    Sao chép url
                                </v-btn>
                            </v-col>
                            <v-col cols="6" md="6" class="text-right">
                                <v-btn class="px-6" :color="colorSet.buttonColor" @click="show_prod_detail = false;"
                                    outlined>
                                    Đóng
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-snackbar v-model="snackbar" :timeout="3000" light class="text-center">
                            {{actionResult}}
                        </v-snackbar>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>
<script>
    //import {roomsRef, firebaseAuth, dbTimestamp} from '@/firestore';
    import {firebaseAuth} from '@/firestore';
    import DataService from "../services/DataService.js";
    import ProductDetail from "../components/ProductDetail.vue";
    import ProductsList from "../components/ProductsList.vue";
    import { mapGetters, mapMutations } from 'vuex';
    import { nomalizeText, createGotoText, parseGotoText} from "../utils/text_utils.js";
    import {addSuggestions} from "../utils/suggestion_utils.js";
    import {urlCommons} from "../utils/commons.js";
    export default {
        props: ['value','products', 'shopInfo','common', 'navigate','gotoX', 'leaveX'],
        components:{
            'product-detail': ProductDetail,
            'products-list': ProductsList,
        },
        data: ()=>({
            panels:[],
            fab:false,
            show_prod_detail: false,
            selected_prod: null,
            suggestions: {},
            loadSuggestion: false,
            viewAsList: false,
            chat_feature: false,
            snackbar:false,
            actionResult: "",
            processed_prods: null,
            first_goto_:false,
        }),
        created(){
            this.panels = [...Array(Object.values(this.products).length).keys()];
            //console.log('this.panels', this.panels);
        },
        mounted(){
            //console.log('this.products:', this.products);
            setTimeout(() => {
                this.$nextTick(() => {
                //console.log("gotoX after timeout", this.gotoX);
                if (this.gotoX) {
                    var tmp = parseGotoText(this.gotoX);
                    if (tmp && tmp.controlId) {
                        this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                        this.$vuetify.goTo('#id-' + tmp.controlId, {offset:100, duration: 1000});
                    }
                }
                })
                this.first_goto_ = true;
            }, 1000);
            this.$watch('gotoX', this.gotoXWatcher);
            this.$watch('leaveX', this.leaveXWatcher);
            //console.log("this.storedHistory:", this.storedHistory);
            this.getSuggestions();

            //
            this.products.forEach(element => {
                if (element.products.length > 30){
                    this.viewAsList = true;
                }
            })

            this.preprocessProdList();
            console.log('MOUNTED');
        },
        computed: {
            ...mapGetters([
                'storedHistory',
                'colorSet',
                'shopId',
            ]),
        },
        methods: {
                    ...mapMutations([
                        'addStoredHistory',
                    ]),
                    gotoXWatcher(newValue) {
                        if (!this.first_goto_){
                            console.log('wait for first_goto_');
                            return;
                        }
                        //this.$vuetify.goTo(0);
                        var tmp = parseGotoText(newValue);
                        if (tmp && tmp.controlId) {
                            this.addStoredHistory(createGotoText(tmp.tabName, tmp.controlId));
                            this.$vuetify.goTo('#id-' + tmp.controlId, { offset: 100, duration: 1000});
                            this.products.forEach(element => {
                                element.products.forEach(prod => {
                                    if (prod.id == tmp.controlId){
                                        this.showProdDetail(prod);
                                    }
                                })
                            });
                        }
                    },
                    leaveXWatcher(){
                        //close dialog when leave my tab
                        this.show_prod_detail = false;
                        this.selected_prod = null;
                    },
                    async preprocessProdList(){
                        var processed_prods = this.products.reduce(function (parent_type_dict, a_parent_type) {
                            if (a_parent_type['parent_type'] in parent_type_dict) {
                                parent_type_dict[a_parent_type['parent_type']]['priority'] += a_parent_type['priority'];
                                parent_type_dict[a_parent_type['parent_type']]['type_list'].push({type: a_parent_type['type'], products:a_parent_type['products'], priority: a_parent_type['priority']});
                            }
                            else {
                                parent_type_dict[a_parent_type['parent_type']] = {type_list: [{type: a_parent_type['type'], products:a_parent_type['products'], priority: a_parent_type['priority']}], priority: a_parent_type['priority']};
                            }
                            return parent_type_dict;
                            
                        }, {})
                        // Create items array
                        var items = Object.keys(processed_prods).map(function (key) {
                                return [key, processed_prods[key]];
                            });
                        // Sort the array based on the second element
                        items.sort(function (first, second) {
                            try{
                                //console.log('sort:',first[1]['priority'], second[1]['priority']);
                                return second[1]['priority'] - first[1]['priority'];
                            }catch{
                                if (first[0] < second[0]){
                                    return 0;
                                }else{
                                    return 1;
                                }

                            }
                        });
                        //sort product
                        await Promise.all(items.map(async (prodType) => {
                            try{
                                prodType[1]['type_list'].sort(function(a, b){
                                    return b['priority'] - a['priority'];
                                })
                                await Promise.all(prodType[1]['type_list'].map(async (prod_list)=>{
                                    prod_list['products'].sort(function(a, b){
                                        if (a['priority'] != b['priority']){
                                            return b['priority'] - a['priority'];
                                        }else{
                                            if (a['created_date'] > b['created_date']){
                                                return 0;
                                            }else{
                                                return 1;
                                            }
                                        }
                                    })
                                }))
                            }catch(e){
                                console.log(e);
                            }
                        }))
                        this.processed_prods = items;
                        console.log("processed_prods:", items);
                    },
                    nomalizeText(text) {
                        return nomalizeText(text);
                    },
                    async getSuggestions() {
                        await Promise.all(this.products.map(async (prodType) => {
                            //var prodType_suggestion = [];
                            /*await Promise.all(prodType.products.map(async(product)=>{
                                var source = createGotoText('tab_product', product.id);
                                const prod_suggestion = await addSuggestions(this.shopInfo.info.id, source);
                                var match_index = prod_suggestion.findIndex(item => {return (item.destination == source);});
                                if (match_index > -1) {
                                    prod_suggestion.splice(match_index, 1);
                                }
                                //console.log('prod_suggestion:', prod_suggestion);
        
                                prodType_suggestion = prodType_suggestion.concat(prod_suggestion);
                            }));*/
                            var id_ = nomalizeText(prodType.type);
                            //console.log('id_:', id_);
                            const prodType_suggestion = await addSuggestions(this.shopInfo.info.id, createGotoText('tab_product', id_));
                            prodType_suggestion.sort(function (a, b) { return a.score - b.score });
                            prodType_suggestion.reverse();
                            //console.log('prodType_suggestion:', prodType_suggestion);
                            this.suggestions[id_] = prodType_suggestion.slice(0, 2);

                        }));
                        this.loadSuggestion = true;
                    },
                    async chatAbout(product) {
                        //console.log('chatAbout');
                        if (!firebaseAuth.currentUser) {
                            //alert('you must login first');
                            //console.log('you must login first');
                            this.common();
                        } else {
                            console.debug('this.shopInfo: ', this.shopInfo);
                            console.debug('product: ', product);
                            var userId = firebaseAuth.currentUser.uid;
                            var displayName = firebaseAuth.currentUser.displayName;
                            var shopId = this.shopInfo.info.id;
                            var productId = product.product_id;
                            var r_find = await DataService.findChatRoom(userId, shopId, productId);
                            var roomId = null;
                            //console.log('r_find: ', r_find);
                            if (r_find.data.room) {
                                roomId = r_find.data.room.id;
                            }
                            console.log('roomId: ', roomId);
                            if (!roomId) {//room not found
                                var room_name = `[${this.shopInfo.info.displayName}] - Liên hệ về ${product.product_name}`;
                                console.log('room_name: ', room_name)
                                var r_create = await DataService.createChatRoom(userId, shopId, null, this.shopInfo.info.staffs, room_name);
                                if (r_create)
                                    roomId = r_create.data.roomId;
                            }
                            console.log('roomId: ', roomId);
                            if (roomId) {
                                this.$router.push({
                                    name: "Chat",
                                    params: { roomId: roomId, roomname: room_name, nickname: displayName }
                                });
                            }
                        }
                    },
                    viewProd(prod) {
                        this.addStoredHistory(createGotoText('tab_product', prod.id));
                        this.showProdDetail(prod);
                    },
                    showProdDetail(prod) {
                        //console.log('[showProdDetail] - prod: ', prod);
                        this.show_prod_detail = true;
                        this.selected_prod = prod;
                    },
                    isMobile() {
                        var isMobile = true;
                        switch (this.$vuetify.breakpoint.name) {
                            case 'xs': isMobile = true; break;
                            case 'sm': isMobile = true; break;
                            case 'md': isMobile = false; break;
                            case 'lg': isMobile = false; break;
                            case 'xl': isMobile = false; break;
                        }
                        return isMobile;
                    },
                    setPanel(index) {
                        if (this.panels.indexOf(index) == -1)
                            this.panels.push(index);
                    },
                    cal_margin(index) {
                        if (index > 0)
                            return "pa-0 ml-4 mr-0 my-0";
                        else
                            return "pa-0 ml-0 mr-0 my-0";
                    },
                    calImageHeight() {
                        var height = "200px";
                        switch (this.$vuetify.breakpoint.name) {
                            case 'xs': height = "200px"; break;
                            case 'sm': height = "200px"; break;
                            case 'md': height = "250px"; break;
                            case 'lg': height = "250px"; break;
                            case 'xl': height = "250px"; break;
                        }
                        return height;
                    },
                    getUrlToProd(prodId) {
                        var url_ = urlCommons['domain'] + '/pr/' + urlCommons['url_shop'] + '/' + this.shopId + '/' + urlCommons['url_quickview']
                        + '/' + urlCommons['url_qkvw_prod'] + '/' + prodId;
                        navigator.clipboard.writeText(url_);
                        this.snackbar = true;
                        //TODO resolve overflow problem
                        //this.actionResult= "Đã sao chép url\n"+ url_;
                        this.actionResult= "Đã sao chép vào clipboard";
                    },
                }
    }
</script>
<style>
    .item-container{
        position: relative;
    }
    .item-img{
        height:300px;
        max-width:100%;
        object-fit: cover;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 640px) {
        .item-img{
            height:200px;
        }
    }
    
</style>
