<template>
    <div class="menuContainer" v-if="refactor_menus.length > 0">       
        <v-menu offset-y :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
                <v-btn small v-model="fab" dark :color="colorSet.backgroundTrans" v-bind="attrs" v-on="on" class="menuButton" :class="colorSet.textColor">
                    <v-icon v-if="fab">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-menu
                    </v-icon>
                    <span v-if="$vuetify.breakpoint.name != 'xs'" class="ml-2">Menu</span>
                </v-btn>
            </template>
            <v-list rounded nav dense class="pa-4 my-0" :class="colorSet.textColor" :color="colorSet.overallColor" :width="calFabWidth()">
                <v-list-group :value="true" v-for="(menu, index) in refactor_menus" :key="index" 
                v-model="menu.active" no-action @click.stop>
                    <template v-slot:activator>
                        <v-list-item-content>
                        <v-list-item-title class="menu-title text-left" v-text="menu.title"></v-list-item-title>
                    </v-list-item-content>
                    </template>
                    <v-list-item link v-for="(nav, index2) in menu.nav_list" :key="index2" class="pl-6 pr-2 ma-0 white--text"
                        @click='gotoContent(nav.destination);'>
                        <v-icon >mdi-rhombus-medium-outline</v-icon>
                        <span class="mx-4 text-truncate menu-title">{{nav.tag}}</span>
                    </v-list-item>
                    <template v-if="menu.groups">
                        <v-list-group class="pa-0 ma-0" :value="true" v-for="(group, index2) in menu.groups" :key = "'group_'+ index2" 
                        v-model="group.active" no-action sub-group @click.stop>
                            <template v-slot:activator>
                                <v-list-item-content>
                                <v-list-item-title class="menu-title pa-0 ma-0" v-text="group.title"></v-list-item-title>
                            </v-list-item-content>
                            </template>
                            <v-list-item link v-for="(sub_nav, index3) in group.nav_list" :key="index3" class="pl-12 pr-2 ma-0 white--text"
                                @click='gotoContent(sub_nav.destination);'>
                                <v-icon small>mdi-rhombus-medium</v-icon>
                                <span class="mx-2 text-truncate menu-title">{{sub_nav.tag}}</span>
                            </v-list-item>
                        </v-list-group>
                    </template>
                </v-list-group>
            </v-list>
        </v-menu>
    </div>        
</template>
<script>
    //import {nomalizeText, createGotoText} from '../utils/text_utils.js'
    import { mapGetters } from 'vuex';
    export default {
        props: ['tab_name', 'menus', 'gotoEvent'],
        data: ()=>({
            fab:false,
            refactor_menus: [],
            //finish_get_menu: false,
        }),
        mounted() {
            this.refactMenus(this.menus);
            /*this.menus.forEach(async(element) => {
                //console.log('element', element);
                var menu = {'groups':[], 'nav_list':[],'title':element.title};
                //var hasGroup = true;
                await Promise.all(element.nav_list.map(nav_element => {
                    if (nav_element.parent_group && nav_element.parent_group != ""){
                        if (menu['groups']){
                            var tmp = menu['groups'].find(item => {return (item.title == nav_element.parent_group);})
                            if (tmp){
                                tmp['nav_list'].push(nav_element);
                            }
                            else{
                                menu['groups'].push({'title':nav_element.parent_group, 'nav_list':[nav_element]});
                            }
                        }
                        else{
                            menu['groups'] = [{'title':nav_element.parent_group, 'nav_list':[nav_element]}];
                        }
                    }
                    else{
                        //hasGroup = false;
                        menu.nav_list.push(nav_element);
                    }
                }));
                
                this.refactor_menus.push(menu);
                //console.log('menu', menu);
            })*/
        },
        computed:{
            ...mapGetters([
                'colorSet',
            ]),
        },
        methods:{
            async refactMenus(menus) {
                //console.log('menus', menus);
                var refacting_menus = [];
                await Promise.all(menus.map(async (element) => {
                    //console.log('element', element);
                    var menu = { 'groups': [], 'nav_list': [], 'title': element.title };
                    //var hasGroup = true;
                    await Promise.all(element.nav_list.map(nav_element => {
                        if (nav_element.parent_group && nav_element.parent_group != "") {
                            if (menu['groups']) {
                                var tmp = menu['groups'].find(item => { return (item.title == nav_element.parent_group); })
                                if (tmp) {
                                    tmp['nav_list'].push(nav_element);
                                }
                                else {
                                    menu['groups'].push({ 'title': nav_element.parent_group, 'nav_list': [nav_element] });
                                }
                            }
                            else {
                                menu['groups'] = [{ 'title': nav_element.parent_group, 'nav_list': [nav_element] }];
                            }
                        }
                        else {
                            //hasGroup = false;
                            menu.nav_list.push(nav_element);
                        }
                    }));

                    refacting_menus.push(menu);
                    //console.log('menu', menu);
                }))
                this.refactor_menus = refacting_menus;
                //console.log('this.refactor_menus',this.refactor_menus);
            },
            calFabWidth(){
                var width = "300px";
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': width = "300px"; break;
                    case 'sm': width = "500px"; break;
                    case 'md': width = "500px"; break;
                    case 'lg': width = "500px"; break;
                    case 'xl': width = "500px"; break;
                }
                return width;
            },
            gotoContent(text){
                this.gotoEvent(text);
            },
        },
    }
</script>
<style>
    .v-speed-dial__list {
        position: relative;
    }
</style>
