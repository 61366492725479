<template>
  <v-card class="pa-4 ma-0 blue-grey lighten-5" flat>

    <v-list three-line v-if="items">
      <template v-for="(item, index) in items" >
        <v-list-item
          :key="item.id"
          @click="editNews(item)"
        >
        <v-list-item-avatar v-if="item.avatar_link">
          <v-img :src="item.avatar_link"></v-img>
        </v-list-item-avatar>
          <v-list-item-avatar v-else>
            <v-img :src="item.avatar"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-row class="pa-0" >
              <v-col cols="8" md="8" class="text-left">
                <v-list-item-title class="text-wrap" v-html="item.title"></v-list-item-title>
              </v-col>
              <v-col cols="4" md="4" class="text-right">
                <span class="suggestionClass">{{getDate(item.created_date)}}</span>
              </v-col>              
            </v-row>
            <v-list-item-subtitle class="text-left">
              <div v-if="item.description" v-html="item.description" class="wrap-text">
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
      </template>
    </v-list>
    <v-card-text style="height: 100px; position: relative">
      <v-fab-transition>
        <v-btn
          color="blue-grey"
          dark
          absolute
          top
          right
          fab
          @click="editNews(null)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-text>
  </v-card>
</template>
<script>
  //import ShopCard from "../components/ShopOverview.vue";
  import DataService from "../../services/DataService.js";
  export default {
    /*props: ['common'],
    name: 'Shops',
    components: {
      ShopCard
    },*/
    data: () => ({
      items: null,
    }),
    mounted(){
      this.getNews(this.$route.params.shopId);
    },

    methods: {
          editNews(item){
            //console.log('editNews(content)',item);
            this.$router.push({
                            name: "ContentEditor",
                            query: { news: item}
                        });
          },
          async getNews(shopId){
            const list= await DataService.getNewsList(shopId).then(result =>{
              //console.log('result', result);
              return result.data.result;
            }).catch(function(error){
              console.log(error);
              return null;
            });
            this.items = list;
          },
          getDate(timeStamp){
            var date = new Date(timeStamp._seconds * 1000);
            return date.toISOString().substring(0, 10);
          }
        },
        
  }
</script>
<style>
  .wrap-text{
    height: 4.6em;
    word-wrap: break-word;
    white-space: normal ;
    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
    padding-bottom: 8px;
  }
</style>
